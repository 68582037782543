import React, { Component } from "react";
import { connect } from "react-redux";
import { bool, string, func, shape } from "prop-types";
import { selectors as i18nSelectors } from "reducers/i18n";
import { actions as checksActions, selectors as checksSelectors } from "reducers/checks";
import { selectors as filterSelectors } from "reducers/filters";
import { selectors as sessionSelectors } from "reducers/session";
import moment from "moment";
import * as config from "util/config";
import Yup from "yup";
import * as i18n from "util/i18n";
import { compose } from "redux";
import * as FormFieldsComponents from "pages/forms/_components/_fields/Index";
import AmountField from "pages/_components/fields/formik/AmountField";
import Date from "pages/_components/fields/DateField";
import { Field, Form, withFormik } from "formik";
import Button from "pages/_components/Button";
import defaultDateFromList from "util/defaultDateFromList";
import { clearFiltersAndSubmit } from "util/form";

class ChecksFilters extends Component {
    static propTypes = {
        fetching: bool,
        dateFrom: string,
        dateTo: string,
        idForm: string.isRequired,
        checkType: string.isRequired,
        filters: shape().isRequired,
        isMobile: bool,
        setValues: func.isRequired,
        currentLang: string.isRequired,
        submitForm: func.isRequired,
    };

    static defaultProps = {
        fetching: false,
        dateFrom: null,
        dateTo: null,
        isMobile: false,
    };

    constructor(props) {
        super(props);
        this.myRef = React.createRef();
    }

    componentDidMount() {
        this.myRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
    }

    getCheckStateOptions = () => {
        const { idForm, filters } = this.props;
        const states = {
            emitted: [
                { "": "all" },
                { D: "debited" },
                { T: "supplierPayment.retired" },
                { U: "supplierPayment.pending" },
                { A: "cancelled" },
                { P: "pending" },
                { R: "rejected" },
                { I: "registered" },
                { B: "blocked" },
                { S: "certified" },
            ],
            received: [
                { "": "all" },
                { V: "accredited" },
                { W: "depositToEstablish24hs" },
                { X: "depositToEstablish48hs" },
                { R: "rejected" },
                { Y: "inCustody" },
            ],
        };

        return filters.checkType
            ? states[filters.checkType].map((state) => ({
                  id: Object.keys(state)[0],
                  label: i18n.get(`${idForm}.${filters.checkType}.state.${Object.values(state)[0]}`),
              }))
            : [];
    };

    handleClearFilters = () => {
        const { setValues, submitForm } = this.props;

        clearFiltersAndSubmit(
            () =>
                setValues({
                    checkNumber: "",
                    dateFrom: defaultDateFromList().checks.dateFrom,
                    dateTo: defaultDateFromList().checks.dateTo,
                    minAmount: {},
                    maxAmount: {},
                    state: "",
                }),
            submitForm,
        );
    };

    render() {
        const {
            fetching,
            idForm,
            dateTo,
            dateFrom,
            filters: { checkType },
            isMobile,
            currentLang,
        } = this.props;
        const maxDaysBack = config.get("checks.deferred.maxDaysBack", 365);
        const maxDaysForward = config.get("checks.deferred.maxDaysForward", 365);

        return (
            <div ref={this.myRef} className="filters-container checks">
                <Form className="form-content">
                    <div className="filters-row">
                        <Field
                            mode="edit"
                            component={FormFieldsComponents.Text}
                            idField="checkNumber"
                            name="checkNumber"
                            idActivity={`${idForm}.send`}
                            idForm={idForm}
                            maxLength={150}
                            isRequired
                            validationRegularExpresion="^[a-zA-Z0-9 áéíóúñ]*$"
                            lang={currentLang}
                        />
                        <Field
                            idField="dateFrom"
                            component={Date}
                            endDate={dateTo}
                            hidePlaceholder
                            idForm={`${idForm}.${checkType}`}
                            isClearable
                            name="dateFrom"
                            selectsStart
                            showMonthYearDropdown
                            startDate={dateFrom}
                            minDate={moment().add(-maxDaysBack, "days")}
                            maxDate={moment().add(maxDaysForward, "days")}
                        />
                        <Field
                            idField="dateToPeriodFilter"
                            component={Date}
                            hidePlaceholder
                            idForm={`${idForm}.${checkType}`}
                            isClearable
                            name="dateTo"
                            selectsEnd
                            showMonthYearDropdown
                            minDate={moment().add(-maxDaysBack, "days")}
                            maxDate={moment().add(maxDaysForward, "days")}
                        />
                        <Field component={AmountField} hideCurrency idForm={idForm} name="minAmount" />
                    </div>
                    <div className="filters-row">
                        <Field component={AmountField} hideCurrency idForm={idForm} name="maxAmount" />
                        <Field
                            component={FormFieldsComponents.Selector}
                            optionList={this.getCheckStateOptions()}
                            key="state"
                            name="state"
                            idField="state"
                            renderAs="combo"
                            idActivity={`${idForm}.send`}
                            idForm={idForm}
                            mode="edit"
                            isRequired
                            lang={currentLang}
                        />
                        <Button
                            block={isMobile}
                            bsStyle="primary"
                            label="product.filters.filter"
                            loading={fetching}
                            type="submit"
                        />
                        <Button
                            block={isMobile}
                            bsStyle="outline"
                            className="btn-clear-filters"
                            image="images/ui-icons/ui-clear-filters.svg"
                            label="product.filters.clearFilters"
                            loading={fetching}
                            onClick={this.handleClearFilters}
                        />
                    </div>
                </Form>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
    currentLang: i18nSelectors.getLang(state),
    filters: checksSelectors.getFilters(state),
    isResetQuery: filterSelectors.isResetFilters(state),
    fetching: checksSelectors.getFetching(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: (props) => ({
            checkNumber: props.filters.checkNumber ? props.filters.checkNumber : "",
            dateFrom: props.filters.dateFrom ? props.filters.dateFrom : null,
            dateTo: props.filters.dateTo ? props.filters.dateTo : null,
            minAmount: props.filters.minAmount ? { amount: props.filters.minAmount } : {},
            maxAmount: props.filters.maxAmount ? { amount: props.filters.maxAmount } : {},
            state: props.filters.state ? props.filters.state : "",
        }),
        validationSchema: (props) =>
            Yup.lazy((values) =>
                Yup.object().shape({
                    dateFrom: values.dateTo
                        ? Yup.date()
                              .nullable()
                              .max(
                                  values.dateTo,
                                  i18n.get(`checks.listChecks.${props.filters.checkType}.dateFrom.error`),
                              )
                              .required(i18n.get("checks.listChecks.dateFrom.empty.error"))
                        : Yup.date()
                              .nullable()
                              .required(i18n.get("checks.listChecks.dateFrom.empty.error")),
                    dateTo: values.dateFrom
                        ? Yup.date()
                              .nullable()
                              .min(
                                  values.dateFrom,
                                  i18n.get(`checks.listChecks.${props.filters.checkType}.dateTo.error`),
                              )
                              .required(i18n.get("checks.listChecks.dateTo.empty.error"))
                        : Yup.date()
                              .nullable()
                              .required(i18n.get("checks.listChecks.dateTo.empty.error")),
                    minAmount: Yup.object().shape({
                        amount: values.maxAmount?.amount
                            ? Yup.number()
                                  .transform((cv, ov) => (ov === "" ? undefined : cv))
                                  .nullable()
                                  .max(values.maxAmount.amount, i18n.get("checks.listChecks.minAmount.error"))
                            : Yup.number()
                                  .transform((cv, ov) => (ov === "" ? undefined : cv))
                                  .nullable(),
                    }),
                    maxAmount: Yup.object().shape({
                        amount: values.minAmount?.amount
                            ? Yup.number()
                                  .transform((cv, ov) => (ov === "" ? undefined : cv))
                                  .nullable()
                                  .min(values.minAmount.amount, i18n.get("checks.listChecks.maxAmount.error"))
                            : Yup.number()
                                  .transform((cv, ov) => (ov === "" ? undefined : cv))
                                  .nullable(),
                    }),
                }),
            ),
        handleSubmit: ({ ...values }, formikBag) => {
            const { filters, dispatch } = formikBag.props;
            const { checkType } = filters;
            const state = values.state[0];
            const minAmount = values.minAmount?.amount || null;
            const maxAmount = values.maxAmount?.amount || null;

            const newFilters = { ...values, minAmount, maxAmount, checkType, state };
            dispatch(checksActions.loadListRequest(newFilters, formikBag));
        },
    }),
)(ChecksFilters);
