import { arrayOf, bool, func, instanceOf, shape, string } from "prop-types";
import React, { Component, createRef } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { push } from "react-router-redux";

import { actions as formsActions, selectors as formsSelectors } from "reducers/form";
import { actions as notificationActions } from "reducers/notification";
import { selectors as sessionSelectors } from "reducers/session";
import * as format from "util/format";
import * as config from "util/config";

import FormSignatures from "pages/forms/_components/FormSignatures";
import DefaultTicketHeader, * as TicketHeaders from "pages/forms/_components/ticketHeaders/Index";
import TransactionTicketCancelConfirmation from "pages/forms/_components/TransactionTicketCancelConfirmation";
import Button from "pages/_components/Button";
import I18n from "pages/_components/I18n";
import Image from "pages/_components/Image";
import * as i18nUtils from "util/i18n";

import "./_transactionTicket.scss";
import Alert from "pages/_components/Alert/Alert";

class TransactionTicket extends Component {
    state = {
        showDetails: false,
    };

    transactionTicketCancelSection = createRef();

    static propTypes = {
        // tells if the form is being rendered from Backoffice
        fromBackoffice: bool,
        isCancelling: bool,
        transaction: shape({
            programed: bool,
            idTransaction: string,
            submitDateTimeAsString: string,
            idTransactionStatus: string,
        }),
        childrenTransactions: arrayOf(
            shape({
                valueDateTime: instanceOf(Date),
                idTransaction: string,
                idTransactionStatus: string,
            }),
        ),
        formActions: shape({}),
        parentTransaction: shape({
            idTransaction: string,
            creationDateTime: instanceOf(Date),
        }),
        children: shape({}),
        // tells if we are rendering to confirm a recent transaction
        ticketConfirmation: bool,
        dispatch: func,
        isDesktop: bool.isRequired,
        activeEnvironment: shape({
            permissions: shape({
                requestTransactionCancellation: bool,
            }),
        }).isRequired,
        withoutTransaction: bool,
        userTransactionDetail: shape({}),
        title: string.isRequired,
        subtitle: string,
        subtitleClean: bool,
        errorMessage: string,
    };

    static defaultProps = {
        fromBackoffice: false,
        isCancelling: false,
        ticketConfirmation: false,
        transaction: {},
        childrenTransactions: null,
        formActions: null,
        parentTransaction: null,
        children: null,
        dispatch: () => {},
        withoutTransaction: false,
        userTransactionDetail: null,
        subtitle: "",
        subtitleClean: false,
        errorMessage: null,
    };

    componentDidMount() {
        const { dispatch, errorMessage } = this.props;

        if (errorMessage !== null) {
            dispatch(notificationActions.showNotification(errorMessage, "error", ["transaction/details"]));
        }
    }

    componentDidUpdate() {
        if (this.transactionTicketCancelSection && this.transactionTicketCancelSection.scrollIntoView) {
            this.transactionTicketCancelSection.scrollIntoView({
                block: "center",
                behavior: "smooth",
            });
        }
    }

    componentWillUnmount() {
        const { dispatch } = this.props;

        dispatch(formsActions.formClosed());
    }

    handleClick = () => {
        this.setState(({ showDetails }) => ({ showDetails: !showDetails }));
    };

    handleCancelTransaction = (event, idTransaction) => {
        event.stopPropagation();
        event.preventDefault();
        const { dispatch } = this.props;
        dispatch(push(`/form/requestTransactionCancellation?referenceToCancel=${idTransaction}`));
    };

    orderingByDate = (obj1, obj2) => {
        const dateA = new Date(obj1.valueDateTime).getTime();
        const dateB = new Date(obj2.valueDateTime).getTime();
        if (dateA < dateB) {
            return 1;
        }

        if (dateA > dateB) {
            return -1;
        }

        return 0;
    };

    getTransactionStatusColor = (idTransactionStatus) => {
        switch (idTransactionStatus) {
            case "SCHEDULED":
            case "ACCEPTED":
            case "FINISHED":
                return "ui-text-success";

            case "CANCELLED":
            case "FAILED":
                return "ui-text-error";

            case "PROCESSING":
            case "PENDING":
                return "ui-text-alert";

            default:
                return "";
        }
    };

    renderChildTransactions(childrenTransactions) {
        return (
            <>
                {childrenTransactions
                    .sort((trx1, trx2) => this.orderingByDate(trx1, trx2))
                    .map((child) => (
                        <div className="table-row" key={child.id}>
                            <div className="table-data">
                                <div className="data-wrapper">
                                    <span className="data-date">
                                        <span className="data-text">{child.valueDateAsString} -</span>
                                    </span>
                                    <Link to={`/transaction/${child.idTransaction}`}>
                                        <span className="data-numeric">{child.idTransaction}</span>
                                    </Link>
                                    <span
                                        className={
                                            child.idTransactionStatus === "FINISHED" ? "pendingOK" : "pendingFAILED"
                                        }>
                                        <I18n id={`transaction.status.${child.idTransactionStatus}`} />
                                    </span>
                                </div>
                            </div>
                        </div>
                    ))}
            </>
        );
    }

    renderCancelledReasonByJob = (rejectionReason, idStatus) => {
        const { transaction } = this.props;
        let isCancelledByJob = false;
        if (!rejectionReason && idStatus === "CANCELLED" && transaction.data?.isCancelledByJob) {
            isCancelledByJob = true;
        }

        return (
            <>
                {isCancelledByJob && (
                    <div className="data-wrapper">
                        <div>
                            <I18n
                                id="forms.transaction.ticket.statusReason"
                                componentProps={{ className: "data-label" }}
                            />
                            <span> {i18nUtils.get("forms.transaction.ticket.statusReason.cancelledByJob")}</span>
                        </div>
                    </div>
                )}
            </>
        );
    };

    render() {
        const {
            childrenTransactions,
            formActions,
            fromBackoffice,
            isCancelling,
            parentTransaction,
            transaction,
            children,
            activeEnvironment,
            withoutTransaction,
            userTransactionDetail,
            title,
            subtitle,
            subtitleClean,
        } = this.props;
        const { idTransaction, submitDateTimeAsString, cancelable, idTransactionStatus, dateTimeAsString } =
            withoutTransaction && userTransactionDetail ? userTransactionDetail : transaction;
        const rejectionReasonDescription = transaction?.data?.rejectionReasonDescription
            ? transaction.data.rejectionReasonDescription
            : null;
        const isAutomaticallyCancellable = config
            .get("transaction.cancel.activities")
            .split("|")
            .includes(transaction.idActivity);

        const TicketHeader =
            TicketHeaders[transaction?.idActivity?.charAt(0).toUpperCase() + transaction?.idActivity?.substr(1)] ||
            DefaultTicketHeader;
        const subtitleText = subtitleClean ? subtitle : i18nUtils.get(subtitle);

        return (
            <>
                <div className="transaction-ticket">
                    {!fromBackoffice && <TicketHeader {...this.props} />}

                    <div className="transaction-ticket__body">
                        <div className="body-header">
                            <div className="body-header__logo">
                                <Image src="images/marivaDetailCheck.svg" />
                            </div>

                            <hr />

                            <h3 className="body-header__title">
                                {i18nUtils.get(title)}
                                {subtitle && ` ${subtitleText[0].toLowerCase()}${subtitleText.slice(1)}`}
                            </h3>
                        </div>

                        <div className="body-content inline">
                            <div className="data-wrapper">
                                <I18n id="forms.transaction.ticket.date" componentProps={{ className: "data-label" }} />
                                <span className="data-text">{submitDateTimeAsString || dateTimeAsString}</span>
                            </div>

                            <div className="data-wrapper">
                                <I18n
                                    id="forms.transaction.ticket.idTransaction"
                                    componentProps={{ className: "data-label" }}
                                />
                                <span className="data-text">{idTransaction}</span>
                            </div>
                            {!withoutTransaction && transaction?.data?.transferNumber && (
                                <div className="data-wrapper">
                                    <I18n
                                        id="forms.transaction.ticket.transferNumber"
                                        componentProps={{ className: "data-label" }}
                                    />
                                    <span className="data-text">{transaction.data.transferNumber}</span>
                                </div>
                            )}

                            {children || null}

                            <hr />

                            <div className="data-wrapper ui-mt-0">
                                <I18n
                                    id="forms.transaction.ticket.status"
                                    componentProps={{ className: "data-label" }}
                                />
                                <span className={`data-text ${this.getTransactionStatusColor(idTransactionStatus)}`}>
                                    <I18n id={`transaction.status.${idTransactionStatus}`} />
                                </span>
                            </div>

                            {this.renderCancelledReasonByJob(rejectionReasonDescription, idTransactionStatus)}

                            {rejectionReasonDescription && (
                                <div className="data-wrapper">
                                    <I18n
                                        id="forms.transaction.ticket.statusReason"
                                        componentProps={{ className: "data-label" }}
                                    />
                                    <span className="data-text">{rejectionReasonDescription}</span>
                                </div>
                            )}

                            {!withoutTransaction && (
                                <>
                                    <div className="formSignatures">
                                        <FormSignatures transaction={transaction} fromBackoffice={fromBackoffice} />
                                    </div>
                                </>
                            )}

                            {parentTransaction && !withoutTransaction && (
                                <Link to={`/transaction/${parentTransaction.idTransaction}`}>
                                    <I18n
                                        id="forms.transaction.ticket.parent"
                                        idTransaction={parentTransaction.idTransaction}
                                        date={format.date(
                                            parentTransaction.creationDateTime,
                                            i18nUtils.get("datepicker.format"),
                                        )}
                                    />
                                </Link>
                            )}

                            {!withoutTransaction && childrenTransactions && childrenTransactions.length > 0 && (
                                <>
                                    <hr />
                                    <I18n
                                        component="h4"
                                        id="forms.transaction.ticket.children.title"
                                        componentProps={{ className: "data-title" }}
                                    />

                                    <div className="table scheduleTable">
                                        <div className="table-body">
                                            {this.renderChildTransactions(childrenTransactions)}
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>

                    {isAutomaticallyCancellable && idTransactionStatus === "PENDING" && (
                        <Alert icon="images/ui-icons/ui-exclamation-orange.svg" className="ui-mt-7 ui-mb-0">
                            <I18n
                                id="forms.transaction.ticket.alert.transactionCancel"
                                componentProps={{ className: "ui-fw-sbold" }}
                            />
                        </Alert>
                    )}

                    {formActions}

                    {isCancelling && <TransactionTicketCancelConfirmation idTransaction={idTransaction} />}
                    {cancelable && !fromBackoffice && activeEnvironment.permissions.requestTransactionCancellation && (
                        <Button
                            bsStyle="outline"
                            label="global.cancel"
                            onClick={(e) => this.handleCancelTransaction(e, idTransaction)}
                            block
                        />
                    )}
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    isCancelling: formsSelectors.getIsCancellingTransaction(state),
    errorMessage: formsSelectors.getErrorMessage(state),
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
});

export default connect(mapStateToProps)(TransactionTicket);
