import React, { useState } from "react";
import { Field } from "formik";
import { bool, func, shape, string } from "prop-types";
import * as stringUtils from "util/string";
import Button from "pages/_components/Button";
import { selectors as echeqSelectors, actions as echeqActions } from "reducers/echeqs";
import { connect } from "react-redux";
import * as config from "util/config";
import * as FormFieldsComponents from "../../../_components/_fields/Index";
import * as maskUtils from "util/mask";

const CustomerEcheqData = ({
    dispatch,
    idActivity,
    genericProps,
    isMobile,
    searchSuccessAction,
    data,
    buttonLabel,
    isLoadingButton,
    customLabel,
}) => {
    const documentTypes = config.getArray("echeq.documentTypesAllowed").map((item) => ({
        id: item,
        label: item,
    }));

    const [searchInfo, setSearchInfo] = useState({ documentType: documentTypes[0].id });

    function searchCustomerBanked() {
        const bankedCustomerRequestData = {
            documentType: searchInfo?.documentType,
            documentNumber: searchInfo?.documentNumber,
            idActivity,
            searchSuccessAction,
        };
        dispatch(echeqActions.getBankedCustomerInfoRequest(bankedCustomerRequestData));
    }

    return (
        <>
            <div className="form-group--mixed-field">
                <Field
                    {...genericProps}
                    component={FormFieldsComponents.Selector}
                    optionList={documentTypes}
                    key="documentType"
                    name="documentType"
                    renderAs="combo"
                    onChange={(e) => {
                        setSearchInfo((prevState) =>
                            Object.assign(prevState, {
                                documentType: e.id,
                            }),
                        );
                    }}
                    value={data?.documentType || [searchInfo.documentType]}
                />

                <Field
                    {...genericProps}
                    component={FormFieldsComponents.Text}
                    key="documentNumber"
                    name="documentNumber"
                    onChange={(e) => {
                        setSearchInfo((prevState) =>
                            Object.assign(prevState, {
                                documentNumber: e,
                            }),
                        );
                    }}
                    value={
                        data?.documentNumber !== undefined && data?.documentNumber !== ""
                            ? stringUtils.formatCuit(data.documentNumber)
                            : ""
                    }
                    mask={maskUtils.cuitMask}
                    idField="documentNumber"
                    customI18nLabel={customLabel}
                    maxLength={11}
                />
            </div>

            <div className="form-group">
                <Button
                    block={isMobile}
                    label={buttonLabel}
                    bsStyle="outline"
                    image="images/ui-icons/ui-search.svg"
                    onClick={() => searchCustomerBanked()}
                    loading={isLoadingButton}
                />
            </div>
        </>
    );
};

CustomerEcheqData.propTypes = {
    dispatch: func.isRequired,
    idActivity: string.isRequired,
    genericProps: shape({}).isRequired,
    isMobile: bool.isRequired,
    searchSuccessAction: func.isRequired,
    data: shape({}).isRequired,
    isLoadingButton: bool.isRequired,
    buttonLabel: string,
    customLabel: string,
};

CustomerEcheqData.defaultProps = {
    buttonLabel: "global.search",
    customLabel: "forms.echeqs.emitEcheq.documentNumber.label",
};

const mapStateToProps = (state) => ({
    isLoadingButton: echeqSelectors.getFetchingOnBankedCustomerInfo(state),
});

export default connect(mapStateToProps)(CustomerEcheqData);
