import { createReducer, makeActionCreator } from "util/redux";

export const types = {
    ECHEQS_DEFERRED_LIST_REQUEST: "echeqs/ECHEQS_DEFERRED_LIST_REQUEST",
    ECHEQS_DEFERRED_LIST_SUCCESS: "echeqs/ECHEQS_DEFERRED_LIST_SUCCESS",
    ECHEQS_DEFERRED_LIST_FAILURE: "echeqs/ECHEQS_DEFERRED_LIST_FAILURE",

    LOAD_MORE_ECHEQS_DEFERRED_SUCCESS: "echeqs/LOAD_MORE_ECHEQS_DEFERRED_SUCCESS",
    LOAD_MORE_ECHEQS_DEFERRED_REQUEST: "echeqs/LOAD_MORE_ECHEQS_DEFERRED_REQUEST",
    LOAD_MORE_ECHEQS_DEFERRED_FAILURE: "echeqs/LOAD_MORE_ECHEQS_DEFERRED_FAILURE",

    ECHEQS_DEFERRED_DETAIL_REQUEST: "echeqs/ECHEQS_DEFERRED_DETAIL_REQUEST",
    ECHEQS_DEFERRED_DETAIL_SUCCESS: "echeqs/ECHEQS_DEFERRED_DETAIL_SUCCESS",
    ECHEQS_DEFERRED_DETAIL_FAILURE: "echeqs/ECHEQS_DEFERRED_DETAIL_FAILURE",

    SET_ECHEQ_TYPE_DEFERRED: "echeqs/SET_ECHEQ_TYPE_DEFERRED",
    LOAD_LIST_REQUEST: "echeqs/LOAD_LIST_REQUEST",
    LOAD_LIST_FAILURE: "echeqs/LOAD_LIST_FAILURE",
    LOAD_LIST_SUCCESS: "echeqs/LOAD_LIST_SUCCESS",
    SET_FILTERS: "echeqs/SET_FILTERS",
    LOAD_MORE_ECHEQS_REQUEST: "echeqs/LOAD_MORE_ECHEQS_REQUEST",
    LOAD_MORE_ECHEQS_SUCCESS: "echeqs/LOAD_MORE_ECHEQS_SUCCESS",
    SET_SELECTED_ECHEQ: "echeqs/SET_SELECTED_ECHEQ",

    SET_ACCOUNT_ID_SELECTED: "echeqs/SET_ACCOUNT_ID_SELECTED",

    RESET_FILTERS: "echeqs/RESET_FILTERS",
    RESET_ECHEQ: "echeqs/RESET_ECHEQ",

    ECHEQS_DETAIL_REQUEST: "echeqs/ECHEQS_DETAIL_REQUEST",
    ECHEQS_DETAIL_SUCCESS: "echeqs/ECHEQS_DETAIL_SUCCESS",
    ECHEQS_DETAIL_FAILURE: "echeqs/ECHEQS_DETAIL_FAILURE",

    SEARCH_BANKED_CUSTOMER_REQUEST: "echeqs/SEARCH_BANKED_CUSTOMER_REQUEST",
    SEARCH_BANKED_CUSTOMER_SUCCESS: "echeqs/SEARCH_BANKED_CUSTOMER_SUCCESS",
    SEARCH_BANKED_CUSTOMER_FAILURE: "echeqs/SEARCH_BANKED_CUSTOMER_FAILURE",
    CLEAN_BANKED_CUSTOMER_INFO: "echeqs/CLEAN_BANKED_CUSTOMER_INFO",

    AUTHORIZED_ENTITY_LIST_REQUEST: "echeqs/AUTHORIZED_ENTITY_LIST_REQUEST",
    AUTHORIZED_ENTITY_LIST_SUCCESS: "echeqs/AUTHORIZED_ENTITY_LIST_SUCCESS",
    AUTHORIZED_ENTITY_LIST_FAILURE: "echeqs/AUTHORIZED_ENTITY_LIST_FAILURE",

    LIST_IMF_REQUEST: "echeqs/LIST_IMF_REQUEST",
    LIST_IMF_FAILURE: "echeqs/LIST_IMF_FAILURE",
    LIST_IMF_SUCCESS: "echeqs/LIST_IMF_SUCCESS",

    ECHEQ_ISSUING_ACCOUNTS_REQUEST: "echeqs/ECHEQ_ISSUING_ACCOUNTS_REQUEST",
    ECHEQ_ISSUING_ACCOUNTS_SUCCESS: "echeqs/ECHEQ_ISSUING_ACCOUNTS_SUCCESS",
    ECHEQ_ISSUING_ACCOUNTS_FAILURE: "echeqs/ECHEQ_ISSUING_ACCOUNTS_FAILURE",

    SET_SELECTED_ACCOUNT: "echeqs/SET_SELECTED_ACCOUNT",

    ECHEQS_DISABLE_ACCOUNT_REQUEST: "echeqs/ECHEQS_DISABLE_ACCOUNT_REQUEST",
    ECHEQS_DISABLE_ACCOUNT_FAILURE: "echeqs/ECHEQS_DISABLE_ACCOUNT_FAILURE",
    ECHEQS_DISABLE_ACCOUNT_SUCCESS: "echeqs/ECHEQS_DISABLE_ACCOUNT_SUCCESS",

    GET_ACCEPT_ECHEQ_TERMS_CONDITIONS_REQUEST: "echeqs/GET_ACCEPT_ECHEQ_TERMS_CONDITIONS_REQUEST",
    GET_ACCEPT_ECHEQ_TERMS_CONDITIONS_SUCCESS: "echeqs/GET_ACCEPT_ECHEQ_TERMS_CONDITIONS_SUCCESS",
    GET_ACCEPT_ECHEQ_TERMS_CONDITIONS_FAILURE: "echeqs/GET_ACCEPT_ECHEQ_TERMS_CONDITIONS_FAILURE",

    DOWNLOAD_ECHEQ_LIST_REQUESTED: "echeqs/DOWNLOAD_ECHEQ_LIST_REQUESTED",
    DOWNLOAD_ECHEQ_LIST_RECEIVED: "echeqs/DOWNLOAD_ECHEQ_LIST_RECEIVED",
    DOWNLOAD_ECHEQ_LIST_REQUEST_FAILED: "echeqs/DOWNLOAD_ECHEQ_LIST_REQUEST_FAILED",

    DOWNLOAD_ECHEQ_MOVEMENT_DETAIL_REQUESTED: "echeqs/DOWNLOAD_ECHEQ_MOVEMENT_DETAIL_REQUESTED",
    DOWNLOAD_ECHEQ_MOVEMENT_DETAIL_RECEIVED: "echeqs/DOWNLOAD_ECHEQ_MOVEMENT_DETAIL_RECEIVED",
    DOWNLOAD_ECHEQ_MOVEMENT_DETAIL_REQUEST_FAILED: "echeqs/DOWNLOAD_ECHEQ_MOVEMENT_DETAIL_REQUEST_FAILED",

    VALIDATE_PERSONALIZED_ECHEQ_REQUEST: "echeqs/VALIDATE_PERSONALIZED_ECHEQ_REQUEST",
    VALIDATE_PERSONALIZED_ECHEQ_SUCCESS: "echeqs/VALIDATE_PERSONALIZED_ECHEQ_SUCCESS",
    VALIDATE_PERSONALIZED_ECHEQ_FAILURE: "echeqs/VALIDATE_PERSONALIZED_ECHEQ_FAILURE",
    SET_VALIDATED_PERSONALIZED_ECHEQS_FROM_TRANSACTION: "echeqs/SET_VALIDATED_PERSONALIZED_ECHEQS_FROM_TRANSACTION",
    REMOVE_PERSONALIZED_ECHEQ_LINE_REQUEST: "echeqs/REMOVE_PERSONALIZED_ECHEQ_LINE_REQUEST",
    REMOVE_PERSONALIZED_ECHEQ_LINE_SUCCESS: "echeqs/REMOVE_PERSONALIZED_ECHEQ_LINE_SUCCESS",
    SET_SHOW_PERSONALIZED_ECHEQ_MODAL: "echeqs/SET_SHOW_PERSONALIZED_ECHEQ_MODAL",
    RESET_PERSONALIZED_ECHEQ: "echeqs/RESET_PERSONALIZED_ECHEQ",
};

export const INITIAL_STATE = {
    echeqsList: [],
    echeqsListDeferred: [],
    isFetching: false,
    totalPages: "",
    echeqDetail: {},
    filters: {
        echeqType: "received",
        orderDirection: "ascendent",
        pageNumber: 1,
        orderColumn: "fecha_pago",
        status: ["TODOS"],
        paymentDateFrom: null,
        paymentDateTo: null,
    },
    hasMoreData: false,
    isFirstFetching: true,
    moreEcheqs: false,
    selectedEcheq: {},
    accountIdSelected: null,
    bankedCustomerInfo: {},
    isFetchingOnBankedCustomer: false,
    listIMF: [],
    errors: {},
    authorizedEntityList: [],
    echeqIssuingAccounts: [],
    accountSelected: {},
    isDownloading: false,
    personalizedEcheqs: {
        validatedEcheqsList: [],
        showModal: false,
        errors: {},
    },
    successEnrollment: false,
    fetchingDownload: false,
};

export default createReducer(INITIAL_STATE, {
    [types.ECHEQS_DEFERRED_LIST_REQUEST]: (state, action) => ({
        ...state,
        isFetching: true,
        filters: {
            ...state.filters,
            ...action.filters,
        },
        isFirstFetching: action.isFirstFetching === true,
    }),
    [types.ECHEQS_DEFERRED_LIST_SUCCESS]: (state, action) => ({
        ...state,
        isFetching: false,
        echeqsListDeferred: action.echeqsListDeferred,
        filters: {
            ...state.filters,
            pageNumber: action.pageNumber,
        },
        totalPages: action.totalPages,
        moreEcheqs: action.moreEcheqs,
    }),
    [types.ECHEQS_DEFERRED_LIST_FAILURE]: (state) => ({
        ...state,
        isFetching: false,
    }),
    [types.LOAD_MORE_ECHEQS_DEFERRED_REQUEST]: (state) => ({
        ...state,
        isFetching: true,
    }),
    [types.LOAD_MORE_ECHEQS_DEFERRED_SUCCESS]: (state, action) => ({
        ...state,
        isFetching: false,
        echeqsListDeferred: state.echeqsListDeferred
            ? state.echeqsListDeferred.concat(action.echeqsListDeferred)
            : action.echeqsListDeferred,
        filters: {
            ...state.filters,
            pageNumber: action.pageNumber,
        },
        totalPages: action.totalPages,
        moreEcheqs: action.moreEcheqs,
    }),
    [types.LOAD_MORE_ECHEQS_DEFERRED_FAILURE]: (state) => ({
        ...state,
        isFetching: false,
    }),
    [types.ECHEQS_DEFERRED_DETAIL_REQUEST]: (state) => ({
        ...state,
        isFetching: true,
    }),
    [types.ECHEQS_DEFERRED_DETAIL_SUCCESS]: (state, action) => ({
        ...state,
        echeqDetail: action.echeqDetail,
        isFetching: false,
    }),
    [types.ECHEQS_DEFERRED_DETAIL_FAILURE]: (state) => ({
        ...state,
        isFetching: false,
    }),
    [types.LOAD_LIST_REQUEST]: (state, action) => ({
        ...state,
        isFetching: true,
        filters: {
            ...state.filters,
            ...action.filters,
        },
        isFirstFetching: true,
    }),
    [types.LOAD_LIST_FAILURE]: (state) => ({
        ...state,
        isFetching: false,
        isFirstFetching: false,
    }),
    [types.LOAD_LIST_SUCCESS]: (state, action) => ({
        ...state,
        echeqsList: action.echeqs,
        hasMoreData: action.pageNumber < action.totalPages,
        isFetching: false,
        isFirstFetching: false,
        filters: {
            ...state.filters,
            pageNumber: action.pageNumber,
        },
        moreEcheqs: action.moreEcheqs,
    }),
    [types.SET_FILTERS]: (state, action) => ({
        ...state,
        filters: {
            ...state.filters,
            ...action.filters,
        },
    }),
    [types.SET_SELECTED_ECHEQ]: (state, action) => ({
        ...state,
        selectedEcheq: action.echeq,
    }),
    [types.LOAD_MORE_ECHEQS_REQUEST]: (state) => ({
        ...state,
        isFetching: true,
    }),
    [types.LOAD_MORE_ECHEQS_SUCCESS]: (state, action) => ({
        ...state,
        isFetching: false,
        echeqsList: state.echeqsList ? state.echeqsList.concat(action?.echeqs) : action?.echeqs,
        hasMoreData: action.pageNumber < action.totalPages,
        filters: {
            ...state.filters,
            pageNumber: action.pageNumber,
        },
        moreEcheqs: action.moreEcheqs,
    }),
    [types.SET_ACCOUNT_ID_SELECTED]: (state, action) => ({
        ...state,
        accountIdSelected: action.accountId,
    }),
    [types.RESET_FILTERS]: (state) => ({
        ...state,
        filters: INITIAL_STATE.filters,
    }),
    [types.ECHEQS_DETAIL_REQUEST]: (state) => ({
        ...state,
        isFetching: true,
    }),
    [types.ECHEQS_DETAIL_FAILURE]: (state) => ({
        ...state,
        isFetching: false,
    }),
    [types.ECHEQS_DETAIL_SUCCESS]: (state, action) => ({
        ...state,
        isFetching: false,
        echeqDetail: action?.data,
    }),
    [types.SEARCH_BANKED_CUSTOMER_REQUEST]: (state) => ({
        ...state,
        isFetchingOnBankedCustomer: true,
    }),
    [types.SEARCH_BANKED_CUSTOMER_FAILURE]: (state, action) => ({
        ...state,
        errors: action.errors,
        isFetchingOnBankedCustomer: false,
    }),
    [types.SEARCH_BANKED_CUSTOMER_SUCCESS]: (state, action) => ({
        ...state,
        bankedCustomerInfo: action?.data,
        isFetchingOnBankedCustomer: false,
    }),
    [types.CLEAN_BANKED_CUSTOMER_INFO]: (state) => ({
        ...state,
        bankedCustomerInfo: INITIAL_STATE.bankedCustomerInfo,
        isFetchingOnBankedCustomer: INITIAL_STATE.isFetchingOnBankedCustomer,
        errors: INITIAL_STATE.errors,
    }),
    [types.AUTHORIZED_ENTITY_LIST_REQUEST]: (state) => ({
        ...state,
        isFetching: true,
    }),
    [types.AUTHORIZED_ENTITY_LIST_FAILURE]: (state, action) => ({
        ...state,
        errors: action.message,
        isFetching: false,
    }),
    [types.AUTHORIZED_ENTITY_LIST_SUCCESS]: (state, action) => ({
        ...state,
        authorizedEntityList: action?.authorizedEntities,
        isFetching: false,
    }),
    [types.LIST_IMF_REQUEST]: (state) => ({
        ...state,
        isFetching: true,
    }),
    [types.LIST_IMF_FAILURE]: (state) => ({
        ...state,
        isFetching: false,
    }),
    [types.LIST_IMF_SUCCESS]: (state, action) => ({
        ...state,
        listIMF: action?.listIMF,
        isFetching: false,
    }),
    [types.SET_SELECTED_ACCOUNT]: (state, action) => ({
        ...state,
        accountSelected: action.account,
    }),
    [types.ECHEQS_DISABLE_ACCOUNT_REQUEST]: (state) => ({
        ...state,
        isFetching: true,
    }),
    [types.ECHEQS_DISABLE_ACCOUNT_SUCCESS]: (state) => ({
        ...state,
        isFetching: false,
    }),
    [types.ECHEQS_DISABLE_ACCOUNT_FAILURE]: (state) => ({
        ...state,
        isFetching: false,
    }),
    [types.ECHEQ_ISSUING_ACCOUNTS_REQUEST]: (state) => ({
        ...state,
        isFetching: true,
    }),
    [types.ECHEQ_ISSUING_ACCOUNTS_SUCCESS]: (state, action) => ({
        ...state,
        isFetching: false,
        echeqIssuingAccounts: action.accounts,
    }),
    [types.ECHEQ_ISSUING_ACCOUNTS_FAILURE]: (state) => ({
        ...state,
        isFetching: false,
    }),
    [types.GET_ACCEPT_ECHEQ_TERMS_CONDITIONS_REQUEST]: (state) => ({
        ...state,
    }),
    [types.GET_ACCEPT_ECHEQ_TERMS_CONDITIONS_SUCCESS]: (state, action) => ({
        ...state,
        successEnrollment: action.successEnrollment,
    }),
    [types.GET_ACCEPT_ECHEQ_TERMS_CONDITIONS_FAILURE]: (state) => ({
        ...state,
    }),
    [types.VALIDATE_PERSONALIZED_ECHEQ_REQUEST]: (state) => ({
        ...state,
        isFetching: true,
    }),
    [types.VALIDATE_PERSONALIZED_ECHEQ_SUCCESS]: (state, action) => ({
        ...state,
        personalizedEcheqs: {
            ...state.personalizedEcheqs,
            validatedEcheqsList: [...state.personalizedEcheqs.validatedEcheqsList, action.validatedEcheq].map(
                (line, index) => ({
                    ...line,
                    lineNumber: index + 1,
                }),
            ),
            errors: {},
        },
        isFetching: false,
    }),
    [types.SET_VALIDATED_PERSONALIZED_ECHEQS_FROM_TRANSACTION]: (state, action) => ({
        ...state,
        personalizedEcheqs: {
            ...state.personalizedEcheqs,
            validatedEcheqsList: [...action.transactionEcheqsList].map((line, index) => ({
                ...line,
                lineNumber: index + 1,
            })),
            errors: {},
        },
        isFetching: false,
    }),
    [types.DOWNLOAD_ECHEQ_LIST_REQUESTED]: (state) => ({
        ...state,
        isDownloading: true,
    }),
    [types.DOWNLOAD_ECHEQ_LIST_REQUEST_FAILED]: (state) => ({
        ...state,
        isDownloading: false,
    }),
    [types.DOWNLOAD_ECHEQ_LIST_RECEIVED]: (state) => ({
        ...state,
        isDownloading: false,
    }),
    [types.VALIDATE_PERSONALIZED_ECHEQ_FAILURE]: (state, action) => ({
        ...state,
        personalizedEcheqs: {
            ...state.personalizedEcheqs,
            errors: action.errors,
        },
        isFetching: false,
    }),
    [types.SET_SHOW_PERSONALIZED_ECHEQ_MODAL]: (state, action) => ({
        ...state,
        personalizedEcheqs: {
            ...state.personalizedEcheqs,
            showModal: action.showModal,
        },
    }),
    [types.RESET_PERSONALIZED_ECHEQ]: (state) => ({
        ...state,
        personalizedEcheqs: INITIAL_STATE.personalizedEcheqs,
    }),
    [types.REMOVE_PERSONALIZED_ECHEQ_LINE_REQUEST]: (state) => ({
        ...state,
        fetching: true,
    }),
    [types.REMOVE_PERSONALIZED_ECHEQ_LINE_SUCCESS]: (state, action) => ({
        ...state,
        personalizedEcheqs: {
            ...state.personalizedEcheqs,
            validatedEcheqsList: action.updatedValidatedEcheqsList,
        },
        fetching: false,
    }),
    [types.DOWNLOAD_ECHEQ_MOVEMENT_DETAIL_REQUESTED]: (state) => ({
        ...state,
        fetchingDownload: true,
    }),
    [types.DOWNLOAD_ECHEQ_MOVEMENT_DETAIL_RECEIVED]: (state) => ({
        ...state,
        fetchingDownload: false,
    }),
    [types.DOWNLOAD_ECHEQ_MOVEMENT_DETAIL_REQUEST_FAILED]: (state) => ({
        ...state,
        fetchingDownload: false,
    }),
});

export const actions = {
    echeqsDeferredListRequest: makeActionCreator(types.ECHEQS_DEFERRED_LIST_REQUEST, "filters"),
    echeqsDeferredListSuccess: makeActionCreator(
        types.ECHEQS_DEFERRED_LIST_SUCCESS,
        "echeqsListDeferred",
        "pageNumber",
        "totalPages",
        "moreEcheqs",
    ),
    echeqsDeferredListFailure: makeActionCreator(types.ECHEQS_DEFERRED_LIST_FAILURE),

    loadMoreEcheqsDeferredRequest: makeActionCreator(types.LOAD_MORE_ECHEQS_DEFERRED_REQUEST, "filters"),
    loadMoreEcheqsDeferredSuccess: makeActionCreator(
        types.LOAD_MORE_ECHEQS_DEFERRED_SUCCESS,
        "echeqsListDeferred",
        "pageNumber",
        "totalPages",
        "moreEcheqs",
    ),
    loadMoreEcheqsDeferredFailure: makeActionCreator(types.LOAD_MORE_ECHEQS_DEFERRED_FAILURE),
    echeqDeferredDetailRequest: makeActionCreator(
        types.ECHEQS_DEFERRED_DETAIL_REQUEST,
        "echeqType",
        "branch",
        "operationNumber",
        "echeqNumber",
        "orderNumber",
        "idEcheq",
    ),

    echeqDeferredDetailSuccess: makeActionCreator(types.ECHEQS_DEFERRED_DETAIL_SUCCESS, "echeqDetail"),
    echeqDeferredDetailFailure: makeActionCreator(types.ECHEQS_DEFERRED_DETAIL_FAILURE),
    loadListRequest: makeActionCreator(types.LOAD_LIST_REQUEST, "filters"),
    loadListFailure: makeActionCreator(types.LOAD_LIST_FAILURE),
    loadListSuccess: makeActionCreator(types.LOAD_LIST_SUCCESS, "echeqs", "pageNumber", "totalPages", "moreEcheqs"),
    setFilters: makeActionCreator(types.SET_FILTERS, "filters"),
    loadMoreEcheqsRequest: makeActionCreator(types.LOAD_MORE_ECHEQS_REQUEST, "filters"),
    loadMoreEcheqsSuccess: makeActionCreator(
        types.LOAD_MORE_ECHEQS_SUCCESS,
        "echeqs",
        "pageNumber",
        "totalPages",
        "moreEcheqs",
    ),

    setSelectedEcheq: makeActionCreator(types.SET_SELECTED_ECHEQ, "echeq"),

    setAccountIdSelected: makeActionCreator(types.SET_ACCOUNT_ID_SELECTED, "accountId"),

    resetFilters: makeActionCreator(types.RESET_FILTERS),
    getEcheqDetailRequest: makeActionCreator(types.ECHEQS_DETAIL_REQUEST, "id", "filters"),
    getEcheqDetailFailure: makeActionCreator(types.ECHEQS_DETAIL_FAILURE),
    getEcheqDetailSuccess: makeActionCreator(types.ECHEQS_DETAIL_SUCCESS, "data"),
    getBankedCustomerInfoRequest: makeActionCreator(types.SEARCH_BANKED_CUSTOMER_REQUEST, "data"),
    getBankedCustomerInfoFailure: makeActionCreator(types.SEARCH_BANKED_CUSTOMER_FAILURE, "errors"),
    getBankedCustomerInfoSuccess: makeActionCreator(types.SEARCH_BANKED_CUSTOMER_SUCCESS, "data"),
    cleanBankedCustomerInfo: makeActionCreator(types.CLEAN_BANKED_CUSTOMER_INFO),
    getAuthorizedEntityListRequest: makeActionCreator(types.AUTHORIZED_ENTITY_LIST_REQUEST),
    getAuthorizedEntityListFailure: makeActionCreator(types.AUTHORIZED_ENTITY_LIST_FAILURE, "errors"),
    getAuthorizedEntityListSuccess: makeActionCreator(types.AUTHORIZED_ENTITY_LIST_SUCCESS, "authorizedEntities"),
    getListIMFRequest: makeActionCreator(types.LIST_IMF_REQUEST, "successAction"),
    getListIMFFailure: makeActionCreator(types.LIST_IMF_FAILURE),
    getListIMFSuccess: makeActionCreator(types.LIST_IMF_SUCCESS, "listIMF"),
    getEcheqIssuingAccountsRequest: makeActionCreator(types.ECHEQ_ISSUING_ACCOUNTS_REQUEST, "status"),
    getEcheqIssuingAccountsFailure: makeActionCreator(types.ECHEQ_ISSUING_ACCOUNTS_FAILURE),
    getEcheqIssuingAccountsSuccess: makeActionCreator(types.ECHEQ_ISSUING_ACCOUNTS_SUCCESS, "accounts"),
    setAccountSelected: makeActionCreator(types.SET_SELECTED_ACCOUNT, "account"),
    disableAccountRequest: makeActionCreator(types.ECHEQS_DISABLE_ACCOUNT_REQUEST, "action", "cbu", "cuit", "account"),
    disableAccountFailure: makeActionCreator(types.ECHEQS_DISABLE_ACCOUNT_FAILURE),
    disableAccountSuccess: makeActionCreator(types.ECHEQS_DISABLE_ACCOUNT_SUCCESS),

    getAcceptEcheqTermsConditionsRequest: makeActionCreator(types.GET_ACCEPT_ECHEQ_TERMS_CONDITIONS_REQUEST),
    getAcceptEcheqTermsConditionsSuccess: makeActionCreator(
        types.GET_ACCEPT_ECHEQ_TERMS_CONDITIONS_SUCCESS,
        "successEnrollment",
    ),
    getAcceptEcheqTermsConditionsFailure: makeActionCreator(types.GET_ACCEPT_ECHEQ_TERMS_CONDITIONS_FAILURE),

    downloadEcheqList: makeActionCreator(types.DOWNLOAD_ECHEQ_LIST_REQUESTED, "format", "filters"),
    downloadEcheqListSuccess: makeActionCreator(types.DOWNLOAD_ECHEQ_LIST_RECEIVED, "echeqs"),
    downloadEcheqListFailure: makeActionCreator(types.DOWNLOAD_ECHEQ_LIST_REQUEST_FAILED),

    downloadEcheqMovementDetail: makeActionCreator(
        types.DOWNLOAD_ECHEQ_MOVEMENT_DETAIL_REQUESTED,
        "echeq",
        "format",
        "subStates",
    ),

    validatePersonalizedEcheqRequest: makeActionCreator(
        types.VALIDATE_PERSONALIZED_ECHEQ_REQUEST,
        "formikBag",
        "values",
        "validateSuccessAction",
    ),
    validatePersonalizedEcheqSuccess: makeActionCreator(types.VALIDATE_PERSONALIZED_ECHEQ_SUCCESS, "validatedEcheq"),
    validatePersonalizedEcheqFailure: makeActionCreator(types.VALIDATE_PERSONALIZED_ECHEQ_FAILURE, "errors"),
    setValidatedPersonalizedEcheqsFromTransaction: makeActionCreator(
        types.SET_VALIDATED_PERSONALIZED_ECHEQS_FROM_TRANSACTION,
        "transactionEcheqsList",
    ),
    setShowPersonalizedEcheqModal: makeActionCreator(types.SET_SHOW_PERSONALIZED_ECHEQ_MODAL, "showModal"),
    resetPersonalizedEcheq: makeActionCreator(types.RESET_PERSONALIZED_ECHEQ),
    removePersonalizedEcheqLineRequest: makeActionCreator(
        types.REMOVE_PERSONALIZED_ECHEQ_LINE_REQUEST,
        "lineNumberToRemove",
        "setFieldsAfterRemove",
        "setFieldValue",
    ),
    removePersonalizedEcheqLineSuccess: makeActionCreator(
        types.REMOVE_PERSONALIZED_ECHEQ_LINE_SUCCESS,
        "updatedValidatedEcheqsList",
    ),
};

export const selectors = {
    getEcheqs: ({ echeqs }) => echeqs.echeqsList,
    getEcheqsDeferred: ({ echeqs }) => echeqs.echeqsListDeferred,
    getFetching: ({ echeqs }) => echeqs.isFetching,
    getFilters: ({ echeqs }) => echeqs.filters,
    getPageNumber: ({ echeqs }) => echeqs.filters.pageNumber,
    getTotalPages: ({ echeqs }) => echeqs.totalPages,
    getMoreEcheqs: ({ echeqs }) => echeqs.moreEcheqs,
    getEcheqDetail: ({ echeqs }) => echeqs.echeqDetail?.detail,
    getFirstFetched: ({ echeqs }) => echeqs.isFirstFetching,
    getHasMoreData: ({ echeqs }) => echeqs.hasMoreData,
    getSelectedEcheq: ({ echeqs }) => echeqs.selectedEcheq,
    getAccountIdSelected: ({ echeqs }) => echeqs.accountIdSelected,
    getBankedCustomerInfo: ({ echeqs }) => echeqs.bankedCustomerInfo,
    getFetchingOnBankedCustomerInfo: ({ echeqs }) => echeqs.isFetchingOnBankedCustomer,
    getListIMF: ({ echeqs }) => echeqs.listIMF,
    getErrors: ({ echeqs }) => echeqs.errors,
    getAuthorizedEntityList: ({ echeqs }) => echeqs.authorizedEntityList,
    getEcheqIssuingAccounts: ({ echeqs }) => echeqs.echeqIssuingAccounts,
    getAccountSelected: ({ echeqs }) => echeqs.accountSelected,
    getSuccessEnrollment: ({ echeqs }) => echeqs.successEnrollment,
    getPersonalizedEcheqsList: ({ echeqs }) => echeqs.personalizedEcheqs.validatedEcheqsList,
    getShowPersonalizedEcheqModal: ({ echeqs }) => echeqs.personalizedEcheqs.showModal,
    getPersonalizedEcheqErrors: ({ echeqs }) => echeqs.personalizedEcheqs.errors,
    getFetchingDownload: ({ echeqs }) => echeqs.fetchingDownload,
};
