import React, { Component } from "react";
import { string, bool, shape } from "prop-types";

import classNames from "classnames";
import I18n from "pages/_components/I18n";
import Image from "pages/_components/Image";

class FieldLabel extends Component {
    static propTypes = {
        idField: string,
        labelKey: string,
        labelText: string,
        mode: string,
        optional: string,
        hideLabel: bool,
        isLegend: bool,
        tooltip: shape({}),
    };

    static defaultProps = {
        mode: "edit",
        hideLabel: false,
        idField: null,
        labelKey: null,
        labelText: null,
        optional: null,
        isLegend: false,
        tooltip: null,
    };

    getFocus = (id) => {
        const element = document.getElementById(id) || document.getElementById(`react-select-${id}--value`);
        if (
            element &&
            element.nodeName !== "INPUT" &&
            element.nodeName !== "TEXTAREA" &&
            element.nodeName !== "SELECT"
        ) {
            element.querySelector(".Select-input").focus();
        }
    };

    render() {
        const { idField, labelKey, labelText, mode, optional, hideLabel, isLegend, tooltip } = this.props;
        const LabelTag = isLegend ? "legend" : "label";

        if (mode === "edit") {
            return (
                <div
                    className={classNames("form-group-text", { "visually-hidden": hideLabel })}
                    role="presentation"
                    onClick={() => this.getFocus(idField)}>
                    <LabelTag
                        id={`${LabelTag}.${idField}`}
                        className={classNames("control-label", { "visually-hidden": isLegend })}
                        htmlFor={idField}>
                        {labelKey ? <I18n id={labelKey} defaultValue={labelText} /> : labelText}
                        {optional && <small className="text-optional">{optional}</small>}
                        {tooltip && (
                            <a role="button" {...tooltip}>
                                <Image src="images/iconos/warning.svg" wrapperClassName="tiny-small-icon" />
                            </a>
                        )}
                    </LabelTag>
                    {isLegend && (
                        <label id={`labelLegend.${idField}`} className="control-label" htmlFor={idField}>
                            {labelKey ? <I18n id={labelKey} /> : labelText}
                            {optional && <small className="text-optional">{optional}</small>}
                        </label>
                    )}
                </div>
            );
        }
        return <span className="data-label">{labelKey ? <I18n id={labelKey} /> : labelText}</span>;
    }
}

export default FieldLabel;
