import React, { useEffect } from "react";
import Yup from "yup";
import { push, routerActions } from "react-router-redux/actions";
import { compose } from "redux";
import { connect } from "react-redux";
import { array, bool, oneOfType, number, shape, func, string } from "prop-types";
import * as config from "util/config";
import * as i18n from "util/i18n";
import {
    types as suppliersTypes,
    selectors as supplierSelectors,
    actions as supplierActions,
    actions,
} from "reducers/paymentToSuppliers";
import { actions as filterActions, selectors as filterSelectors } from "reducers/filters";
import { selectors as formSelectors } from "reducers/form";
import { selectors as i18nSelectors } from "reducers/i18n";
import { actions as notificationActions } from "reducers/notification";
import * as supplierUtils from "util/paymentSupplier";
import { validateToResetFilters } from "util/filters";
import moment from "moment";

import { Field, Form, withFormik } from "formik";
import Table from "pages/_components/Table";
import Notification from "pages/_components/Notification";
import getFiltersStatus from "util/getFiltersStatus";
import defaultDateFromList from "util/defaultDateFromList";
import { clearFiltersAndSubmit } from "util/form";

import * as FormFieldsComponents from "../forms/_components/_fields/Index";
import { selectors as sessionSelectors } from "../../reducers/session";
import MainContainer from "../_components/MainContainer";
import Button from "../_components/Button";
import Head from "../_components/Head";
import ProductList from "../_components/product/List";
import FormattedDate from "../_components/FormattedDate";
import FormattedAmount from "../_components/FormattedAmount";
import SeuoLabel from "../accounts/SeuoLabel/SeuoLabel";
import I18n from "../_components/I18n";
import ChevromRight from "../_components/listItem/ChevromRight";
import Dropdown from "../_components/Dropdown";
import Loader from "../_components/Loader";
import Image from "../_components/Image";
import GenericItem from "../../components/GenericItem/GenericItem";
import DateSelector from "../_components/fields/DateField";

const FORM_ID = "forms.payments.suppliers.fileList";
const SupplierPaymentFileList = ({
    isMobile,
    isFetching,
    paymentFiles,
    dispatch,
    pageNumber,
    isLastPage,
    filters,
    activeEnvironment,
    history,
    values,
    setFieldValue,
    setFieldError,
    isResetQuery,
    idActivityOnFilter,
    reloadData,
    isFirstFetch,
    setValues,
    currentLang,
    submitForm,
}) => {
    const { toggleState } = filters;

    const fileTypeIds = config.getArray(`payment.supplier.fileTypes.pending`);

    function hasPermission(item) {
        let permission = item.toLowerCase();
        if (["echeck", "check"].includes(item.toLowerCase())) {
            permission = "checkEcheq";
        } else if (item === "retention") {
            permission = "withholdings";
        }

        return !!activeEnvironment.permissions[`suppliers.${permission}`] || permission === "all";
    }

    const fileTypeOptions = fileTypeIds
        .filter((item) => hasPermission(item))
        .map((id) => ({
            id,
            label: i18n.get(`${FORM_ID}.pending.options.fileTypes.${id}.label`),
        }));
    const signatureStateIds = config.getArray("transactions.pending.sign.states").filter((id) => id !== "DRAFT");

    const signatureStateOptions = signatureStateIds.map((id) => ({
        id,
        label: i18n.get(`transaction.pending.status.${id}`).toUpperCase(),
    }));

    const processingStatusIds = config.getArray("payment.supplier.statusForSent");
    const processingStatusOptions = processingStatusIds.map((id) => ({
        id,
        label: i18n.get(`${FORM_ID}.sent.options.status.${id}.label`).toUpperCase(),
    }));

    const defaultDateFrom = defaultDateFromList().paymentsList.dateFrom;
    const defaultDateTo = defaultDateFromList().paymentsList.dateTo;

    useEffect(() => {
        dispatch(supplierActions.resetUploadedFileData());
        handleTabClick(toggleState);

        return () => {
            if (!history.location.pathname.toLowerCase().includes("suppliers")) {
                dispatch(supplierActions.resetFilter());
            }
        };
    }, []);

    function handleTabClick(value) {
        dispatch(filterActions.validateToResetFilters(suppliersTypes.RESET_FILTERS, FORM_ID));

        if (validateToResetFilters(isResetQuery, idActivityOnFilter, FORM_ID)) {
            dispatch(supplierActions.setFileStatus(value));
            dispatch(supplierActions.setPageNumber(1));

            dispatch(
                supplierActions.setFilter({
                    dateFrom: defaultDateFrom,
                    dateTo: defaultDateTo,
                    fileType: "ALL",
                    processingStatus: "ALL",
                    signatureStatus: "Todos",
                    toggleState: value,
                }),
            );

            dispatch(
                supplierActions.paymentFilesRequested(
                    {
                        pageNumber: 1,
                        signatureStatus: "todos",
                        fileStatus: value,
                        dateFrom: defaultDateFrom,
                        dateTo: defaultDateTo,
                        fileType: "ALL",
                    },
                    true,
                ),
            );
        } else if (reloadData) {
            const defaultFilters = {
                pageNumber: 1,
                fileStatus: filters.toggleState,
                ...filters,
            };

            dispatch(supplierActions.paymentFilesRequested(defaultFilters, false));
        }

        dispatch(filterActions.setReloadData(false));
    }

    const handleOnClick = (e, file) => {
        if (
            e.target.classList.contains("btn-link") ||
            e.target.classList.contains("svg-icon") ||
            e.target.classList.length === 0
        ) {
            return false;
        }

        // Go to Payment Details Page
        dispatch(supplierActions.seeDetailById(file));
        dispatch(supplierActions.setComeFromProcessed(false));
        dispatch(push(`/suppliersPaymentList/supplierList/`));
        dispatch(filterActions.setResetFilters(false));

        return null;
    };

    const handleClearFilters = () => {
        clearFiltersAndSubmit(
            () =>
                setValues({
                    dateFrom: defaultDateFromList().paymentsList.dateFrom,
                    dateTo: defaultDateFromList().paymentsList.dateTo,
                    fileType: ["ALL"],
                    processingStatus: ["ALL"],
                    signatureStatus: ["Todos"],
                    toggleState: filters.toggleState,
                }),
            submitForm,
        );
    };

    const toggleOptions = () => (
        <div className="tabs-wrapper ui-mb-7">
            <Button
                label={`${FORM_ID}.toggle.pending.label`}
                onClick={() => handleTabClick("pending")}
                bsStyle="outline"
                className={`${toggleState === "pending" ? "active" : ""}`}
            />
            <Button
                label={`${FORM_ID}.toggle.sent.label`}
                onClick={() => handleTabClick("sent")}
                bsStyle="outline"
                className={`${toggleState === "sent" ? "active" : ""}`}
            />
        </div>
    );

    const handleNavigationToResultPage = (status) => {
        dispatch(supplierActions.setResetFiltersQuery(true));
        dispatch(push(`/paymentSuppliers/fileQueryList/${status.toLowerCase()}`));
        dispatch(supplierActions.resetAnswerFilesData());
    };

    const renderHeader = () => {
        if (isMobile) {
            return <Head showLogo arrowWhite additionalClassName="blue-main-header-mobile" />;
        }

        return (
            <Head
                addLinkToLabel="forms.payments.suppliers.fileList.buttonHeader.fileUpload.label"
                addLinkTo="/"
                customButtonIcon="/images/ui-icons/ui-upload-file-white.svg"
                customButtonStyle="secondary"
                customButtonOnClick={() => history.push("/paymentSuppliers")}
                accessibilityTextId="menu.investments.mutualFund"
            />
        );
    };

    const fetchMoreData = () =>
        dispatch(
            supplierActions.paymentFilesRequested(
                {
                    pageNumber,
                    signatureStatus: filters?.signatureStatus ?? "todos",
                    fileType: filters?.fileType,
                    dateFrom: filters?.dateFrom,
                    dateTo: filters?.dateTo,
                    processingStatus: filters?.processingStatus,
                    fileStatus: toggleState,
                },
                false,
            ),
        );

    const renderFiltersBtnActions = () => (
        <>
            <Button
                bsStyle="primary"
                label="product.filters.filter"
                loading={isFetching}
                onClick={() => {
                    dispatch(supplierActions.setPaymentFilesFilters(filters));
                    dispatch(supplierActions.setPageNumber(1));
                }}
                type="submit"
                block={isMobile}
            />
            <Button
                bsStyle="outline"
                className="btn-clear-filters"
                label="product.filters.clearFilters"
                loading={isFetching}
                onClick={handleClearFilters}
                image="images/ui-icons/ui-clear-filters.svg"
                block={isMobile}
            />
        </>
    );

    const renderFilters = () => {
        const { dateFrom } = values;

        return (
            <div className={`filters-container ${toggleState === "sent" ? "supplier-payment-list-sent" : ""}`}>
                <Form className={`form-content ${isMobile && toggleState === "pending" ? "ui-mb-0" : ""}`}>
                    {toggleState === "pending" ? (
                        <>
                            <Field
                                isRequired
                                idActivity="payments.suppliers.fileList.send"
                                component={FormFieldsComponents.Selector}
                                optionList={fileTypeOptions}
                                mode="edit"
                                key="fileType"
                                name="fileType"
                                idField="fileType"
                                idForm={FORM_ID}
                                renderAs="combo"
                                defaultValue={filters?.fileType ? [filters?.fileType] : ["ALL"]}
                                lang={currentLang}
                            />

                            <Field
                                component={FormFieldsComponents.Selector}
                                optionList={signatureStateOptions}
                                key="signatureStatus"
                                name="signatureStatus"
                                idField="signatureStatus"
                                idForm={FORM_ID}
                                renderAs="combo"
                                mode="edit"
                                isRequired
                                defaultValue={filters?.signatureStatus ? [filters?.signatureStatus] : ["Todos"]}
                                idActivity="payments.suppliers.fileList.send"
                                lang={currentLang}
                            />
                            {renderFiltersBtnActions()}
                        </>
                    ) : (
                        <>
                            {!isMobile && (
                                <div className="filters-row">
                                    <Button
                                        label={`${FORM_ID}.goProcessed`}
                                        onClick={() => handleNavigationToResultPage("PROCESSED")}
                                        bsStyle="outline"
                                    />
                                    <Button
                                        label={`${FORM_ID}.goRetunrs`}
                                        onClick={() => handleNavigationToResultPage("REJECTED")}
                                        bsStyle="outline"
                                    />
                                </div>
                            )}
                            <div className="filters-row">
                                <Field
                                    isRequired
                                    idActivity="payments.suppliers.fileList.send"
                                    component={FormFieldsComponents.Selector}
                                    optionList={fileTypeOptions}
                                    mode="edit"
                                    key="fileType"
                                    name="fileType"
                                    idField="fileType"
                                    renderAs="combo"
                                    defaultValue={filters?.fileType ? [filters?.fileType] : ["ALL"]}
                                    lang={currentLang}
                                    idForm={FORM_ID}
                                />
                                <Field
                                    component={FormFieldsComponents.Selector}
                                    optionList={processingStatusOptions}
                                    key="processingStatus"
                                    name="processingStatus"
                                    idField="processingStatus"
                                    renderAs="combo"
                                    mode="edit"
                                    isRequired
                                    defaultValue={filters?.processingStatus ? [filters?.processingStatus] : ["ALL"]}
                                    idActivity="payments.suppliers.fileList.send"
                                    lang={currentLang}
                                    idForm={FORM_ID}
                                />

                                <Field
                                    idField="dateFrom"
                                    component={DateSelector}
                                    hidePlaceholder
                                    idForm={FORM_ID}
                                    name="dateFrom"
                                    selectsStart
                                    showMonthYearDropdown
                                    startDate={dateFrom}
                                    style={{ display: toggleState === "sent" ? "none" : "block" }}
                                    minDate={defaultDateFrom}
                                    maxDate={moment()}
                                    isClearable
                                    idActivity="payments.suppliers.fileList.send"
                                    onChange={(e) => {
                                        setFieldValue("dateFrom", e);
                                        setFieldError("dateFrom", null);
                                    }}
                                />

                                <Field
                                    idField="dateTo"
                                    component={DateSelector}
                                    hidePlaceholder
                                    idForm={FORM_ID}
                                    name="dateTo"
                                    selectsStart
                                    showMonthYearDropdown
                                    startDate={dateFrom}
                                    style={{ display: toggleState === "sent" ? "none" : "block" }}
                                    isClearable={false}
                                    idActivity="payments.suppliers.fileList.send"
                                    minDate={values.dateFrom || defaultDateFrom}
                                    maxDate={moment()}
                                    onChange={(e) => {
                                        setFieldValue("dateTo", e);
                                        setFieldError("dateTo", null);
                                    }}
                                />
                                {renderFiltersBtnActions()}
                            </div>
                        </>
                    )}
                </Form>
            </div>
        );
    };

    const getOptionsToRender = (file) => {
        const options = [];
        const { fileName, statusDescription, transactionId, internalFileName, internalMemberFileName } = file;
        if (toggleState === "pending") {
            options.push(
                <Button
                    key={`${FORM_ID}.pending.associatedActions.sign.label`}
                    label={`${FORM_ID}.pending.associatedActions.sign.label`}
                    className="dropdown__item-btn"
                    bsStyle="link"
                    onClick={() => {
                        if (hasPermission(supplierUtils.typeFileByName(fileName))) {
                            dispatch(routerActions.push(`/transaction/${transactionId}`));
                            dispatch(filterActions.setResetFilters(false));
                        } else {
                            dispatch(
                                notificationActions.showNotification(
                                    i18n.get("forms.payments.suppliers.fileList.permission.error"),
                                    "error",
                                    ["SupplierPaymentFileList"],
                                ),
                            );
                        }
                    }}
                />,
            );
        } else if (statusDescription && statusDescription.toLowerCase().includes("error")) {
            options.push(
                <Button
                    key={`${FORM_ID}.pending.associatedActions.viewError.label`}
                    label={`${FORM_ID}.pending.associatedActions.viewError.label`}
                    className="dropdown__item-btn"
                    bsStyle="link"
                    onClick={() => {
                        dispatch(
                            actions.setFilterErrors({
                                fileName,
                                internalFileName,
                                internalMemberFileName,
                            }),
                        );
                        dispatch(push("/paymentSuppliers/errors"));
                        dispatch(filterActions.setResetFilters(false));
                    }}
                />,
            );
        }

        return options;
    };

    const renderItem = (file) => {
        const {
            id,
            fileName,
            creationDate,
            totalLines,
            totalAmount,
            debitAccount,
            statusDescription,
            shippingDate,
            currency,
            signatureStatus,
            status,
        } = file;

        const statusNewDescription = status
            ? i18n.get(`${FORM_ID}.sent.options.status.${status}.label`, "NO_DEFINED").toUpperCase()
            : statusDescription?.toUpperCase();
        let statusClass;
        switch (status) {
            case "P":
                statusClass = "ui-text-success";
                break;

            case "E":
                statusClass = "ui-text-error";
                break;

            default:
                break;
        }

        const getTooltipText = (icon) => {
            switch (icon) {
                case "ui-sign-ok.svg":
                    return "userOk";

                case "ui-sign-pend.svg":
                    return "userPending";

                case "ui-sign-pend-gray.svg":
                    return "allPending";

                default:
                    return "allPending";
            }
        };

        const tooltipProps = {
            "data-tooltip": i18n.get(
                `transactions.list.item.signatures.${getTooltipText(signatureStatus)}.tooltipLabel`,
            ),
            "data-tooltip-position": "top-right",
        };

        return isMobile ? (
            <>
                <GenericItem
                    key={id}
                    role="button"
                    hoverBehavior={false}
                    customOption={
                        <>
                            {toggleState === "pending" && (
                                <div className="ui-dflex-center" {...tooltipProps}>
                                    <Image src={`/images/ui-icons/${signatureStatus}`} className="svg-icon" />
                                </div>
                            )}
                            <Dropdown
                                image="images/ui-icons/ui-dropdown.svg"
                                bsStyle="link"
                                className="btn-small"
                                pullRight>
                                {getOptionsToRender(file)}
                            </Dropdown>
                        </>
                    }>
                    <div className="data-wrapper">
                        <div className="data-label">
                            <I18n id={`${FORM_ID}.table.header.name.label`} />
                        </div>
                        <span className="data-text">
                            {fileName.endsWith(".dat") ? fileName.slice(0, -4) : fileName}
                        </span>
                    </div>

                    <div className="data-wrapper">
                        <div className="data-label">
                            <I18n id={`${FORM_ID}.table.header.creationDate.label`} />
                        </div>
                        <FormattedDate date={toggleState === "pending" ? creationDate : shippingDate} />
                    </div>

                    <div className="data-wrapper">
                        <div className="data-label">
                            <I18n id={`${FORM_ID}.table.header.debitAccount.label`} />
                        </div>
                        <span className="data-text">{debitAccount}</span>
                    </div>

                    <div className="data-wrapper">
                        <div className="data-label">
                            <I18n id={`${FORM_ID}.table.header.totalLines.label`} />
                        </div>
                        <span className="data-text">{totalLines}</span>
                    </div>

                    <div className="data-wrapper">
                        <div className="data-label">
                            <I18n id={`${FORM_ID}.table.header.amount.label`} />
                        </div>
                        <FormattedAmount maximumDecimals="2" currency={currency} quantity={totalAmount} />
                    </div>

                    {toggleState === "sent" && (
                        <div className="data-wrapper">
                            <div className="data-label">
                                <I18n id={`${FORM_ID}.table.header.status.label`} />
                            </div>
                            <span className={`data-text ${statusClass}`}>{statusNewDescription}</span>
                        </div>
                    )}
                </GenericItem>
            </>
        ) : (
            <Table.Row key={id} onClick={(e) => handleOnClick(e, file)} role="button">
                <Table.Data align="left">
                    <span className="data-text">{fileName.endsWith(".dat") ? fileName.slice(0, -4) : fileName}</span>
                </Table.Data>
                <Table.Data align="left">
                    <FormattedDate date={toggleState === "pending" ? creationDate : shippingDate} />
                </Table.Data>
                <Table.Data align="left">
                    <span className="data-text">{debitAccount}</span>
                </Table.Data>
                <Table.Data align="left">
                    <span className="data-text">{totalLines}</span>
                </Table.Data>
                <Table.Data align="left">
                    <FormattedAmount maximumDecimals="2" currency={currency} quantity={totalAmount} />
                </Table.Data>

                {toggleState === "pending" && (
                    <Table.Data className="table-data-icon" align="right">
                        <div className="ui-dflex-center" {...tooltipProps}>
                            <Image src={`/images/ui-icons/${signatureStatus}`} className="svg-icon" />
                        </div>
                    </Table.Data>
                )}

                {toggleState === "sent" && (
                    <Table.Data align="left">
                        <div className={`data-text ${statusClass}`}>{statusNewDescription}</div>
                    </Table.Data>
                )}

                <Table.Data className="table-data-icon" align="right">
                    {getOptionsToRender(file).length > 0 && (
                        <Dropdown
                            image="images/ui-icons/ui-dropdown.svg"
                            bsStyle="link"
                            className="btn-small"
                            label=""
                            pullRight>
                            {getOptionsToRender(file)}
                        </Dropdown>
                    )}
                </Table.Data>
                <Table.Data className="table-data-icon" align="right">
                    <ChevromRight />
                </Table.Data>
            </Table.Row>
        );
    };

    const renderList = (list, renderLoadMore) => {
        if (isMobile) {
            return (
                <>
                    <div className="generic-movements-wrapper">{list}</div>
                    <SeuoLabel />
                    {renderLoadMore()}
                </>
            );
        }
        return (
            <>
                <Table className="gridTable">
                    <Table.Header>
                        <Table.HeaderData align="left">
                            <I18n id={`${FORM_ID}.table.header.name.label`} />
                        </Table.HeaderData>
                        {toggleState === "pending" ? (
                            <Table.HeaderData align="left">
                                <I18n id={`${FORM_ID}.table.header.creationDate.label`} />
                            </Table.HeaderData>
                        ) : (
                            <Table.HeaderData align="left">
                                <I18n id={`${FORM_ID}.table.header.shippingDate.label`} />
                            </Table.HeaderData>
                        )}
                        <Table.HeaderData align="left">
                            <I18n id={`${FORM_ID}.table.header.debitAccount.label`} />
                        </Table.HeaderData>
                        <Table.HeaderData align="left">
                            <I18n id={`${FORM_ID}.table.header.totalLines.label`} />
                        </Table.HeaderData>
                        <Table.HeaderData align="right">
                            <I18n id={`${FORM_ID}.table.header.amount.label`} />
                        </Table.HeaderData>
                        {toggleState === "sent" ? (
                            <Table.HeaderData align="left">
                                <I18n id={`${FORM_ID}.table.header.status.label`} />
                            </Table.HeaderData>
                        ) : (
                            <Table.HeaderData />
                        )}
                        <Table.HeaderData />
                        <Table.HeaderData />
                    </Table.Header>
                    <Table.Body>{list}</Table.Body>
                </Table>
                <SeuoLabel />
                {renderLoadMore()}
            </>
        );
    };

    const defaultFilters = {
        dateFrom: defaultDateFrom,
        dateTo: defaultDateTo,
        fileType: "ALL",
        processingStatus: "ALL",
        signatureStatus: "Todos",
        toggleState,
    };

    let noMoreDataText;
    const noDataText = `${FORM_ID}.${toggleState}.empty.label`;

    const withFilters = getFiltersStatus({ filters, defaultFilters });

    if (withFilters) {
        noMoreDataText = `${FORM_ID}.${toggleState}.noMoreData.withFilters`;
    } else {
        noMoreDataText = `${FORM_ID}.${toggleState}.noMoreData.default`;
    }

    return (
        <>
            <Notification scopeToShow="SupplierPaymentFileList" />
            {renderHeader()}
            <MainContainer>
                <div className="title-left-header">
                    <I18n id={`${FORM_ID}.title`} component="h1" />
                </div>

                <div className="container-left">
                    {toggleOptions()}

                    {renderFilters()}

                    {isFetching && isFirstFetch ? (
                        <Loader />
                    ) : (
                        <ProductList
                            fetching={isFetching}
                            items={paymentFiles}
                            renderItem={renderItem}
                            firstFetched={isFirstFetch}
                            lastPage={isLastPage}
                            pageNumber={pageNumber}
                            filters={filters}
                            loadMoreText="investments.mutualFund.movements.searchMore"
                            noDataText={noDataText}
                            noFiltersDataText={`${FORM_ID}.message.noFoundWithFilters.label`}
                            onLoadMoreClick={() => fetchMoreData()}
                            noMoreDataText={noMoreDataText}
                            isMobile={isMobile}
                            alertText={
                                toggleState === "pending" ? "forms.payments.suppliers.fileList.pending.alert" : null
                            }
                            defaultFilters={defaultFilters}
                            listMessagesParams={{
                                noMoreDataText: {
                                    DAYS: config.get("payment.supplier.default_days.search", 15),
                                },
                                noDataText: {
                                    DAYS: config.get("payment.sugpplier.default_days.search", 15),
                                },
                            }}>
                            {renderList}
                        </ProductList>
                    )}
                </div>
            </MainContainer>
        </>
    );
};

SupplierPaymentFileList.propTypes = {
    isFetching: bool.isRequired,
    paymentFiles: oneOfType([array]).isRequired,
    pageNumber: number.isRequired,
    isLastPage: bool.isRequired,
    history: shape({}).isRequired,
    activeEnvironment: shape({}).isRequired,
    filters: shape({}).isRequired,
    values: shape({}).isRequired,
    dispatch: func.isRequired,
    isMobile: bool.isRequired,
    setFieldValue: func.isRequired,
    setFieldError: func.isRequired,
    isResetQuery: bool.isRequired,
    idActivityOnFilter: string.isRequired,
    reloadData: bool.isRequired,
    isFirstFetch: bool.isRequired,
    setValues: func.isRequired,
    currentLang: string.isRequired,
    submitForm: func.isRequired,
};

const mapStateToProps = (state) => ({
    isFetching: supplierSelectors.isFetching(state),
    paymentFiles: supplierSelectors.getFiles(state),
    pageNumber: supplierSelectors.getPageNumber(state),
    isLastPage: supplierSelectors.isLastPage(state),
    transaction: formSelectors.getTransaction(state),
    filters: supplierSelectors.getPaymentFilesFilters(state),
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
    isResetQuery: filterSelectors.isResetFilters(state),
    idActivityOnFilter: filterSelectors.getIdActivityOnFilter(state),
    reloadData: filterSelectors.isReloadData(state),
    isFirstFetch: supplierSelectors.getFirstFetching(state),
    currentLang: i18nSelectors.getLang(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: (props) => ({
            fileType: props?.filters?.fileType ? [props?.filters?.fileType] : ["ALL"],
            signatureStatus: props?.filters?.signatureStatus ? [props?.filters?.signatureStatus] : ["Todos"],
            processingStatus: props?.filters?.processingStatus ? [props?.filters?.processingStatus] : ["ALL"],
            dateFrom: props?.filters?.dateFrom ?? defaultDateFromList().paymentsList.dateFrom,
            dateTo: props?.filters?.dateTo ?? defaultDateFromList().paymentsList.dateTo,
        }),
        validationSchema: () =>
            Yup.lazy((values) =>
                Yup.object().shape({
                    dateFrom: values.dateTo
                        ? Yup.date()
                              .nullable()
                              .max(values.dateTo, i18n.get(`${FORM_ID}.validation.dateTo.message`))
                              .required(i18n.get(`${FORM_ID}.dateFrom.empty.error`))
                        : Yup.date()
                              .nullable()
                              .required(i18n.get(`${FORM_ID}.dateFrom.empty.error`)),
                    dateTo: values.dateFrom
                        ? Yup.date()
                              .nullable()
                              .min(values.dateFrom, i18n.get(`${FORM_ID}.validation.dateTo.message`))
                              .required(i18n.get(`${FORM_ID}.dateTo.empty.error`))
                        : Yup.date()
                              .nullable()
                              .required(i18n.get(`${FORM_ID}.dateTo.empty.error`)),
                }),
            ),
        handleSubmit: ({ ...values }, formikBag) => {
            const { dispatch, filters } = formikBag.props;

            dispatch(
                supplierActions.setFilter({
                    fileType: values?.fileType[0],
                    signatureStatus: values?.signatureStatus[0],
                    processingStatus: values?.processingStatus[0],
                    dateFrom: values?.dateFrom || defaultDateFromList().paymentsList.dateFrom,
                    dateTo: values?.dateTo || defaultDateFromList().paymentsList.dateTo,
                    toggleState: filters.toggleState,
                }),
            );

            dispatch(
                supplierActions.paymentFilesRequested(
                    {
                        pageNumber: 1,
                        fileType: values?.fileType[0] === "ALL" ? null : values?.fileType[0],
                        signatureStatus: values?.signatureStatus[0],
                        processingStatus: values?.processingStatus[0],
                        dateFrom: values?.dateFrom,
                        dateTo: values?.dateTo,
                        fileStatus: filters.toggleState,
                    },
                    true,
                ),
            );
        },
    }),
)(SupplierPaymentFileList);
