import React, { Component } from "react";
import { string, shape, bool } from "prop-types";

import * as i18nUtils from "util/i18n";
import * as configUtils from "util/config";
import Password from "pages/_components/fields/credentials/Password";

const styles = {
    hidden: {
        opacity: 0,
        position: "absolute",
        zIndex: "-9999",
    },
};

class Credential extends Component {
    static propTypes = {
        type: string,
        field: shape({}),
        copyEnabled: bool,
        additionalClassName: string,
        showLabelInfo: bool,
        inputRef: shape({}),
    };

    static defaultProps = {
        type: "password",
        field: {},
        copyEnabled: true,
        additionalClassName: "",
        showLabelInfo: true,
        inputRef: React.createRef(),
    };

    render() {
        const { type, additionalClassName, showLabelInfo, inputRef, ...props } = this.props;

        if (type === "password") {
            return (
                <Password
                    pattern={configUtils.get("core.password.pattern")}
                    autoComplete="off"
                    copyEnabled
                    additionalClassName={additionalClassName}
                    inputRef={inputRef}
                    {...props}
                />
            );
        }

        if (type === "otp") {
            const {
                field: { name },
            } = this.props;

            return (
                <>
                    {showLabelInfo && <span className="ui-mb-7">{i18nUtils.get("form.credential.otp.verify")}</span>}
                    {/*
                        The objetive of this input is to avoid the differents
                        behaviors of autocomplete="off" of browsers.
                    */}
                    <input name={name} style={styles.hidden} type="password" aria-hidden tabIndex="-1" />
                    <Password
                        autoComplete="off"
                        pattern="[0-9]{0,6}"
                        copyEnabled
                        {...props}
                        warningMessage={i18nUtils.get("form.credential.otp.warning")}
                        additionalClassName={additionalClassName}
                        inputRef={inputRef}
                    />
                </>
            );
        }

        return null;
    }
}

export default Credential;
