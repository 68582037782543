import React, { Component } from "react";
import ReactDOM from "react-dom";
import classNames from "classnames";
import { bool, element, func, number, oneOf, oneOfType, shape, string, array } from "prop-types";
import { compose } from "redux";

import * as i18n from "util/i18n";
import { getNestedObject } from "util/general";

import FieldError from "pages/_components/fields/FieldError";
import FieldWarning from "pages/_components/fields/FieldWarning";
import FieldLabel from "pages/_components/fields/FieldLabel";
import { resizableRoute } from "pages/_components/Resizable";
import withFocus from "pages/_components/withFocus";
import MaskedInput from "react-text-mask";

class TextField extends Component {
    static propTypes = {
        additionalClassName: string,
        autoCapitalize: string,
        autoComplete: string,
        autoFocus: bool,
        classNameForViewMode: string,
        copyEnabled: bool,
        field: shape({
            onBlur: func,
            onChange: func,
            name: string,
            value: oneOfType([number, string]),
        }).isRequired,
        form: shape({}).isRequired,
        handleOnChange: func,
        hideLabel: bool,
        hidePlaceholder: bool,
        idField: string,
        idForm: string,
        idValidation: string,
        inputFunctions: element,
        inputRef: oneOfType([func, shape({})]),
        isDesktop: bool.isRequired,
        isFocused: bool,
        isMobile: bool.isRequired,
        isMobileNative: bool.isRequired,
        labelText: string,
        mask: oneOfType([array, string]),
        maxLength: number,
        minLength: number,
        mobileOS: string.isRequired,
        mode: oneOf(["view", "edit"]),
        nestedErrorsObject: bool,
        optional: string,
        pattern: string,
        placeholderText: string,
        renderAs: string,
        toggleIsFocused: func,
        type: string,
        validationRegularExpresion: string,
        warningMessage: string,
        onKeyUp: func,
    };

    static defaultProps = {
        autoCapitalize: "sentences",
        autoComplete: "on",
        autoFocus: false,
        handleOnChange: null,
        hideLabel: false,
        hidePlaceholder: false,
        inputFunctions: null,
        inputRef: React.createRef(),
        maxLength: 50,
        mode: "edit",
        nestedErrorsObject: false,
        optional: "",
        pattern: null,
        renderAs: "input",
        type: "text",
        warningMessage: "",
        labelText: null,
        placeholderText: null,
        isFocused: false,
        toggleIsFocused: null,
        idForm: "",
        classNameForViewMode: "",
        copyEnabled: true,
        idField: "",
        minLength: 0,
        validationRegularExpresion: "",
        idValidation: "",
        mask: null,
        additionalClassName: "",
        onKeyUp: null,
    };

    state = {
        hasWarning: false,
    };

    componentDidMount() {
        window.addEventListener("resize", this.onResize);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.onResize);
    }

    onResize = () => {
        const { isDesktop, isFocused, mobileOS } = this.props;

        if (isFocused && !isDesktop && mobileOS === "Android") {
            /* eslint-disable-next-line react/no-find-dom-node */
            ReactDOM.findDOMNode(this).scrollIntoView({ block: "center", behavior: "smooth" });
        }
    };

    handleBlur = (event) => {
        const { field, toggleIsFocused } = this.props;

        this.setState({
            hasWarning: false,
        });
        field.onBlur(event);
        toggleIsFocused();
    };

    handleOnChange = (event) => {
        const { field, handleOnChange, maxLength, pattern } = this.props;
        const { onChange } = field;

        /* eslint no-control-regex: "off" */
        const reg = /[^\x00-\xFF]/g;

        if (!reg.test(event.target.value)) {
            if (!pattern || event.target.validity.valid) {
                if (event.target.value.length <= maxLength) {
                    if (handleOnChange) {
                        handleOnChange(event);
                    }

                    onChange(event);
                    this.setState({
                        hasWarning: false,
                    });
                    return;
                }
            }
        }
        this.setState({
            hasWarning: true,
        });
    };

    handleOnCopy = (event) => {
        const { copyEnabled } = this.props;
        if (!copyEnabled) {
            event.preventDefault();
        }
    };

    renderLabel = () => {
        const { idForm, field, labelText, optional } = this.props;
        const text = labelText || `${idForm}.${field.name}.label`;
        return <FieldLabel labelKey={text} optional={optional} />;
    };

    renderPlaceholder = () => {
        const { idForm, field, hidePlaceholder, placeholderText } = this.props;
        if (hidePlaceholder) {
            return "";
        }
        if (placeholderText !== null) {
            return placeholderText;
        }
        return i18n.get(`${idForm}.${field.name}.placeholder`);
    };

    customMinLengthValidation = ({ target }) => {
        const { minLength, idValidation, validationRegularExpresion } = this.props;

        const { value } = target;

        if (minLength) {
            if (value && value.length < minLength) {
                target.setCustomValidity(
                    i18n.get("generic.text.field.minLength.warning.message", null, {
                        MINLENGTH: minLength,
                        CURRENTLENGTH: value.length,
                    }),
                );
            } else {
                target.setCustomValidity("");
            }
        }

        if (idValidation && validationRegularExpresion) {
            const rexp = new RegExp(validationRegularExpresion);
            if (!rexp.test(value)) {
                switch (idValidation) {
                    case "email":
                        target.setCustomValidity(i18n.get("generic.text.field.email.validationError", null));
                        break;
                    default:
                        break;
                }
            } else {
                target.setCustomValidity("");
            }
        }
    };

    handleChangeMask = (event) => {
        const { form, field } = this.props;
        form.setFieldValue(field.name, event.target.value);
    };

    render() {
        const {
            field,
            form: { touched, errors },
            inputFunctions,
            inputRef,
            hideLabel,
            idForm,
            toggleIsFocused,
            isFocused,
            renderAs: Element,
            autoCapitalize,
            mode,
            optional,
            nestedErrorsObject,
            warningMessage,
            labelText,
            placeholderText,
            classNameForViewMode,
            idField,
            mask,
            additionalClassName,
            renderAs,
            maxLength,
            minLength,
            type,
            pattern,
            autoComplete,
            validationRegularExpresion,
            idValidation,
            onKeyUp,
        } = this.props;

        const { hasWarning } = this.state;
        const hasError = nestedErrorsObject
            ? getNestedObject(touched, field.name.split(".")) && getNestedObject(errors, field.name.split("."))
            : errors[field.name];

        if (mode !== "edit" && !field.value) {
            return null;
        }

        const fieldLabel = labelText || `${idForm}.${field.name}.label`;
        const id = idField || `${idForm}.${field.name}`;

        return (
            <div
                className={classNames(
                    "form-group",
                    {
                        "has-error": hasError,
                        "has-focus": isFocused,
                        "has-warning": hasWarning,
                    },
                    additionalClassName,
                )}>
                <FieldLabel hideLabel={hideLabel} labelKey={fieldLabel} optional={optional} idField={id} />

                {mode !== "edit" && <div className={classNameForViewMode}>{field.value}</div>}

                {mode === "edit" &&
                    (mask ? (
                        <div className="input-group">
                            <MaskedInput
                                className="form-control"
                                guide={false}
                                mask={mask}
                                onBlur={toggleIsFocused}
                                onFocus={toggleIsFocused}
                                value={field.value}
                                onChange={this.handleChangeMask}
                                placeholder={placeholderText}
                            />
                        </div>
                    ) : (
                        <div className="input-group">
                            <Element
                                id={id}
                                className={`form-control ${renderAs === "textarea" ? "ui-scrollbar" : ""}`}
                                placeholder={this.renderPlaceholder()}
                                {...field}
                                type={type}
                                minLength={minLength}
                                maxLength={maxLength}
                                pattern={pattern}
                                autoComplete={autoComplete}
                                validationregularexpresion={validationRegularExpresion}
                                idvalidation={idValidation}
                                onFocus={toggleIsFocused}
                                onBlur={this.handleBlur}
                                ref={inputRef}
                                onChange={this.handleOnChange}
                                autoCapitalize={autoCapitalize}
                                onCopy={this.handleOnCopy}
                                onCut={this.handleOnCopy}
                                onInvalid={this.customMinLengthValidation}
                                onKeyUp={onKeyUp}
                            />
                            {inputFunctions}
                        </div>
                    ))}

                {hasError && (
                    <FieldError
                        error={nestedErrorsObject ? getNestedObject(errors, field.name.split(".")) : errors[field.name]}
                    />
                )}

                {hasWarning && <FieldWarning warning={warningMessage} />}
            </div>
        );
    }
}

export default compose(resizableRoute, withFocus)(TextField);
