import { Field, Form, Formik } from "formik";
import * as FormConfirmationMessages from "pages/forms/_components/_confirmations/Index";
import Button from "pages/_components/Button";
import Credential from "pages/_components/fields/credentials/Credential";
import { arrayOf, func, shape, string, bool } from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { selectors as formSelectors } from "reducers/form";
import { compose } from "redux";
import { flattenArray, removeDuplicateItems } from "util/array";
import * as i18n from "util/i18n";
import Yup from "yup";
import { Mixpanel } from "util/clickstreaming";
import * as stringUtils from "util/string";

class FormConfirmation extends Component {
    static propTypes = {
        idForm: string.isRequired,
        credentials: arrayOf(string).isRequired,
        dispatch: func.isRequired,
        submitAction: func.isRequired,
        submitActionParams: shape({
            idActivity: string.isRequired,
        }).isRequired,
        currentLang: string.isRequired,
        metadata: shape({}).isRequired,
        renderFields: func.isRequired,
        renderFieldsTermsAndConditions: func,
        customLabelMessage: string,
        showMessageAboveField: bool,
        renderEditableFields: func,
        values: shape({}).isRequired,
        errors: shape({}).isRequired,
        handleCancel: func,
        confirmLabelEmpty: bool,
        additionalFieldsToValidate: arrayOf(string),
        formConfirmationClass: string,
        isMobile: bool,
    };

    static defaultProps = {
        renderFieldsTermsAndConditions: null,
        customLabelMessage: "",
        showMessageAboveField: false,
        renderEditableFields: null,
        handleCancel: null,
        confirmLabelEmpty: false,
        additionalFieldsToValidate: [],
        formConfirmationClass: null,
        isMobile: false,
    };

    componentDidMount() {
        const { errors } = this.props;

        if (errors) {
            this.form.setErrors(errors);
        }
    }

    componentDidUpdate(prevProps) {
        const { idActivity: prevIdActivity } = prevProps.submitActionParams;
        const { idActivity } = this.props.submitActionParams;

        if (prevIdActivity !== idActivity) {
            this.form.setFieldValue("otp", "");
        }
    }

    isMessage = (key) => {
        let response = false;
        const message = i18n.get(key);
        if (message !== `*${key}*`) {
            response = true;
        }
        return response;
    };

    validationSchema = () => {
        const { credentials, additionalFieldsToValidate } = this.props;
        let customSchema;
        if (additionalFieldsToValidate) {
            customSchema = Yup.object().shape(
                additionalFieldsToValidate.reduce(
                    (previousValue, currentValue) => ({
                        ...previousValue,
                        [currentValue]: Yup.string().required(
                            i18n.get(
                                this.isMessage(`form.field.messageCustom.validationSchema.${currentValue}`)
                                    ? `form.field.messageCustom.validationSchema.${currentValue}`
                                    : "global.fields.errors.required",
                            ),
                        ),
                    }),
                    {},
                ),
            );
        }
        const defaultSchema = Yup.object().shape(
            credentials.reduce(
                (values, credential) => ({
                    ...values,
                    [credential]: Yup.string().required(i18n.get(`form.credential.${credential}.required`)),
                }),
                {},
            ),
        );
        return customSchema ? defaultSchema.concat(customSchema) : defaultSchema;
    };

    handleSubmit = (credentialValues, formikBag) => {
        const { dispatch, submitAction, submitActionParams, renderEditableFields, credentials, values } = this.props;
        const finalCredentialValues = { ...credentialValues };
        let finalSubmitActionParams;

        if (submitActionParams?.idActivity === "echeqs.emitEcheq.send") {
            // para agregar params extras que vengan del preview
            finalSubmitActionParams = { ...submitActionParams, values };
        } else {
            finalSubmitActionParams = { ...submitActionParams };
        }

        if (renderEditableFields) {
            const focusedField = document.activeElement;
            if (focusedField) {
                focusedField.blur();
            }

            const filteredValues = Object.entries(credentialValues).reduce((accumulator, [key, value]) => {
                if (value === "") {
                    return accumulator;
                }
                if (Array.isArray(value) && value.length > 0 && value[0].text !== undefined) {
                    const arrayValues = [];
                    value.forEach((item) => {
                        arrayValues.push(item.text || item);
                    });
                    return {
                        ...accumulator,
                        [key]: arrayValues,
                    };
                }
                return {
                    ...accumulator,
                    [key]: stringUtils.trim(value),
                };
            }, {});

            Mixpanel.track_form(formikBag?.props?.id, filteredValues);

            // separo values de credenciales del resto de los campos editables en preview si los hubiera
            const fieldList = Object.keys(credentialValues);
            finalSubmitActionParams.values = { ...credentialValues };
            fieldList.forEach((fieldName) => {
                if (credentials.includes(fieldName)) {
                    delete finalSubmitActionParams.values[fieldName];
                } else {
                    delete finalCredentialValues[fieldName];
                }
            });
        }
        dispatch(submitAction({ ...finalSubmitActionParams, credentials: finalCredentialValues, formikBag }));
    };

    renderDefaultConfirmation = () => {
        const { renderFields } = this.props;
        return <div className="form-confirmation-content-fields inline">{renderFields()}</div>;
    };

    render() {
        const {
            credentials,
            idForm,
            submitActionParams: { idActivity },
            currentLang,
            metadata,
            renderFieldsTermsAndConditions,
            customLabelMessage,
            showMessageAboveField,
            renderEditableFields,
            values,
            handleCancel,
            confirmLabelEmpty,
            formConfirmationClass,
            isMobile,
        } = this.props;

        let FormConfirmationMessage;
        if (idForm !== null) {
            FormConfirmationMessage =
                FormConfirmationMessages[(idForm.charAt(0).toUpperCase() + idForm.substr(1)).replace(".", "_")];
        }
        const requireSomeCredential = credentials.length > 0;
        let dynamicKey = `form.confirm.${idActivity}`;
        const message = i18n.get(dynamicKey);
        if (message === `*${dynamicKey}*`) {
            dynamicKey = "global.confirm";
        }

        const renderMessage = () =>
            customLabelMessage !== "" && (
                <span className="fw-bold">{customLabelMessage && i18n.get(customLabelMessage)}</span>
            );
        const initialValuesCredentials = credentials.reduce(
            (credentialValues, credential) => ({ ...credentialValues, [credential]: "" }),
            {},
        );
        const initialValues = renderEditableFields
            ? { ...values, ...initialValuesCredentials }
            : initialValuesCredentials;

        return (
            <div className={`form-confirmation-container ${formConfirmationClass || ""}`}>
                <>
                    {FormConfirmationMessage ? (
                        <FormConfirmationMessage metadata={metadata} currentLang={currentLang} />
                    ) : (
                        this.renderDefaultConfirmation(requireSomeCredential)
                    )}
                </>

                <Formik
                    ref={(el) => {
                        this.form = el;
                    }}
                    validateOnBlur={false}
                    validateOnChange={false}
                    initialValues={initialValues}
                    validationSchema={this.validationSchema}
                    onSubmit={this.handleSubmit}>
                    {({ isSubmitting }) => (
                        <>
                            <Form className="token-form">
                                {renderEditableFields && renderEditableFields()}
                                {renderFieldsTermsAndConditions ? renderFieldsTermsAndConditions() : ""}

                                <div className="token-field">
                                    {!showMessageAboveField && !confirmLabelEmpty && renderMessage()}
                                    {showMessageAboveField && renderMessage()}

                                    {credentials.map((credential) => (
                                        <Field
                                            idForm="form.credential"
                                            name={credential}
                                            component={Credential}
                                            type={credential}
                                            showLabelInfo={!customLabelMessage}
                                        />
                                    ))}

                                    <div
                                        className={`form-confirmation-footer ${
                                            credentials.length === 0 ? "ui-mt-0" : ""
                                        }`}>
                                        {isMobile && (
                                            <Button
                                                block
                                                type="submit"
                                                label={dynamicKey}
                                                bsStyle="primary"
                                                loading={isSubmitting}
                                            />
                                        )}
                                        {handleCancel && (
                                            <Button
                                                block={isMobile}
                                                type="button"
                                                label={i18n.get("global.cancel")}
                                                bsStyle="outline"
                                                // loading={fetching}
                                                defaultLabelText={i18n.get("global.cancel")}
                                                onClick={handleCancel}
                                            />
                                        )}
                                        {!isMobile && (
                                            <Button
                                                type="submit"
                                                label={dynamicKey}
                                                bsStyle="primary"
                                                loading={isSubmitting}
                                            />
                                        )}
                                    </div>
                                </div>
                            </Form>
                        </>
                    )}
                </Formik>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    name: formSelectors.getName(state),
    metadata: formSelectors.getMetadata(state),
    submitAction: formSelectors.getSubmitAction(state),
    submitActionParams: formSelectors.getSubmitActionParams(state),
    credentials: compose(
        (array) => array.filter((item) => item !== "accessToken"),
        removeDuplicateItems,
        flattenArray,
        (array) => array.map(({ credentials }) => credentials),
    )(formSelectors.getCredentialsGroups(state)),
    errors: formSelectors.getErrors(state),
});

export default connect(mapStateToProps)(FormConfirmation);
