import React, { Component } from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { func, instanceOf, shape, number, string, bool, arrayOf, any, oneOfType } from "prop-types";
import { isMobileNative } from "util/device";

import * as i18nUtils from "util/i18n";
import defaultDateFromList from "util/defaultDateFromList";
import { actions as notificationActions } from "reducers/notification";
import { actions as transactionsActions, selectors as transactionsSelectors } from "reducers/transactions";

import I18n from "pages/_components/I18n";
import Table from "pages/_components/Table";
import TransactionItem from "pages/transactions/_components/TransactionItem";
import { SALARY_PAYMENT_ID_FORM } from "constants.js";
import SeuoLabel from "pages/accounts/SeuoLabel/SeuoLabel";
import ProductList from "pages/_components/product/List";
import moment from "moment";
import getFiltersStatus from "util/getFiltersStatus";
import Loader from "pages/_components/Loader";

class List extends Component {
    static propTypes = {
        defaultFilters: shape({
            dateFrom: oneOfType([instanceOf(Date), instanceOf(moment)]),
            dateTo: oneOfType([instanceOf(Date), instanceOf(moment)]),
            pageNumber: number,
            filter: string,
        }),
        dispatch: func.isRequired,
        pageNumber: number,
        onlyPendings: bool,
        pendingDispatch: bool,
        filters: shape({
            idFilter: string,
            dateFrom: oneOfType([instanceOf(Date), instanceOf(moment)]),
            dateTo: oneOfType([instanceOf(Date), instanceOf(moment)]),
            pageNumber: number,
            minAmount: number,
            maxAmount: number,
        }),
        transactions: arrayOf(any),
        isDesktop: bool.isRequired,
        hasMoreData: bool.isRequired,
        fetching: bool.isRequired,
        showEnvironment: bool,
        itemsAreClickeable: bool,
        firstFetched: bool.isRequired,
    };

    static defaultProps = {
        defaultFilters: null,
        pageNumber: 1,
        onlyPendings: false,
        pendingDispatch: false,
        filters: null,
        showEnvironment: false,
        itemsAreClickeable: true,
        transactions: [],
    };

    fetchMoreTransactions = () => {
        const { defaultFilters, dispatch, pageNumber, onlyPendings, pendingDispatch } = this.props;
        let { filters } = this.props;

        filters = filters ? { ...filters, pageNumber } : { ...defaultFilters, pageNumber };

        dispatch(transactionsActions.loadMoreTransactionsRequest(filters, onlyPendings, pendingDispatch));
    };

    onMobileClickTransaction = (transaction) => {
        const { dispatch, itemsAreClickeable } = this.props;

        if (
            transaction.transaction.idActivity === "pay.transfers.massive.send" &&
            transaction.transaction.idTransactionStatus === "DRAFT" &&
            isMobileNative
        ) {
            dispatch(
                notificationActions.showNotification(i18nUtils.get("massive.payments.mobile.disable"), "error", [
                    "transactions",
                ]),
            );
        } else {
            dispatch(
                push(
                    itemsAreClickeable ? `/transaction/${transaction.transaction.idTransaction}` : `/transactions/list`,
                ),
            );
        }
    };

    onDesktopItemCLick = (transaction) => {
        const { dispatch, isDesktop, itemsAreClickeable } = this.props;

        if (
            transaction.transaction.idForm === SALARY_PAYMENT_ID_FORM &&
            !isDesktop &&
            transaction.transaction.idTransactionStatus === "DRAFT"
        ) {
            dispatch(
                notificationActions.showNotification(i18nUtils.get("massive.payments.mobile.disable"), "error", [
                    "transactions",
                ]),
            );
        } else {
            dispatch(
                push(
                    itemsAreClickeable ? `/transaction/${transaction.transaction.idTransaction}` : `/transactions/list`,
                ),
            );
        }
    };

    renderItem = (transaction) => {
        const { isDesktop, showEnvironment, onlyPendings } = this.props;

        if (isDesktop) {
            return (
                <Table.Row
                    key={transaction.transaction.idTransaction}
                    role="button"
                    onClick={() => this.onDesktopItemCLick(transaction)}>
                    <TransactionItem
                        key={`transaction-${transaction.transaction.idTransaction}`}
                        transaction={transaction}
                        isDesktop={isDesktop}
                        showEnvironment={showEnvironment}
                        onlyPendings={onlyPendings}
                    />
                </Table.Row>
            );
        }
        return (
            <TransactionItem
                key={`transaction-${transaction.transaction.idTransaction}`}
                transaction={transaction}
                isDesktop={isDesktop}
                showEnvironment={showEnvironment}
                onClick={() => this.onMobileClickTransaction(transaction)}
                onlyPendings={onlyPendings}
            />
        );
    };

    renderList = (list, renderLoadMore) => {
        const { isDesktop, showEnvironment, onlyPendings } = this.props;

        return isDesktop ? (
            <>
                <Table className="gridTable transactions">
                    <Table.Header>
                        <Table.HeaderData align="left">
                            <I18n id="transactions.list.header.state" />
                        </Table.HeaderData>
                        <Table.HeaderData align="left">
                            <I18n id="transactions.list.header.transactionName" />
                        </Table.HeaderData>
                        {showEnvironment && (
                            <Table.HeaderData align="left">
                                <I18n id="transactions.list.header.client" />
                            </Table.HeaderData>
                        )}
                        <Table.HeaderData align="left">
                            <I18n id="transactions.list.header.creationDate" />
                        </Table.HeaderData>
                        <Table.HeaderData align="left">
                            <I18n id="transactions.list.header.submitDate" />
                        </Table.HeaderData>
                        <Table.HeaderData align="right">
                            <I18n id="transactions.list.header.amount" />
                        </Table.HeaderData>
                        <Table.HeaderData />
                        {onlyPendings && <Table.HeaderData />}
                    </Table.Header>
                    <Table.Body>{list}</Table.Body>
                </Table>
                <SeuoLabel />
                {renderLoadMore()}
            </>
        ) : (
            <>
                <div className="generic-movements-wrapper">{list}</div>
                <SeuoLabel isTable isMobile={!isDesktop} />
                {renderLoadMore()}
            </>
        );
    };

    render() {
        const {
            transactions,
            hasMoreData,
            fetching,
            pageNumber,
            filters,
            firstFetched,
            onlyPendings,
            isDesktop,
        } = this.props;

        const defaultFilters = {
            dateFrom: defaultDateFromList().transactions,
            dateTo: moment(),
            filter: "last",
            selectedFilter: "last",
            transactionType: ["all"],
            state: ["Todos"],
        };

        const listType = onlyPendings ? "pendingTransactions" : "transactions";

        const noDataText = `${listType}.list.none`;

        const withFilters = getFiltersStatus({ filters, defaultFilters });

        let noMoreDataText;

        if (withFilters) {
            noMoreDataText = `${listType}.list.noMoreTransactions.withFilters`;
        } else {
            noMoreDataText = `${listType}.list.noMoreTransactions.default`;
        }

        return fetching && firstFetched ? (
            <Loader />
        ) : (
            <ProductList
                fetching={fetching}
                items={transactions}
                renderItem={this.renderItem}
                lastPage={!hasMoreData}
                pageNumber={pageNumber}
                onLoadMoreClick={this.fetchMoreTransactions}
                firstFetched={firstFetched}
                filters={filters}
                loadMoreText="transactions.list.moreTransactions"
                noDataText={noDataText}
                noFiltersDataText="transactions.list.filters.noData"
                noMoreDataText={noMoreDataText}
                defaultFilters={defaultFilters}
                isMobile={!isDesktop}>
                {this.renderList}
            </ProductList>
        );
    }
}

const mapStateToProps = (state) => ({
    hasMoreData: transactionsSelectors.getHasMoreData(state),
    fetching: transactionsSelectors.getFetching(state),
    pageNumber: transactionsSelectors.getPageNumber(state),
    filters: transactionsSelectors.getFilters(state),
    firstFetched: transactionsSelectors.getFirstFetch(state),
});

export default connect(mapStateToProps)(List);
