import React, { Component } from "react";
import { bool, shape, func } from "prop-types";
import { compose } from "redux";
import { connect } from "react-redux";
import Yup from "yup";
import moment from "moment";
import { actions as userTransactionsActions, selectors as userTransactionsSelectors } from "reducers/userTransactions";
import * as i18n from "util/i18n";
import * as config from "util/config";
import defaultDateFromList from "util/defaultDateFromList";

import { Field, Form, withFormik } from "formik";
import Button from "pages/_components/Button";
import Date from "pages/_components/fields/DateField";
import Selector from "pages/_components/fields/formik/Selector";
import { clearFiltersAndSubmit } from "util/form";

const FORM_ID = "userTransactions.list.filters";

class Filters extends Component {
    static propTypes = {
        fetching: bool,
        values: shape({}),
        isDesktop: bool.isRequired,
        setValues: func.isRequired,
        submitForm: func.isRequired,
    };

    static defaultProps = {
        fetching: false,
        values: shape({
            dateFrom: null,
            dateTo: null,
        }),
    };

    handleClearFilters = () => {
        const { setValues, submitForm } = this.props;

        clearFiltersAndSubmit(
            () =>
                setValues({
                    activity: "ALL",
                    dateFrom: defaultDateFromList().userTransactions.dateFrom,
                    dateTo: defaultDateFromList().userTransactions.dateTo,
                }),
            submitForm,
        );
    };

    render() {
        const {
            fetching,
            values: { dateFrom, dateTo },
            isDesktop,
        } = this.props;

        const maxDaysBack = config.get("userTransactions.maxDaysBack", 365);

        const activities = [
            {
                value: "ALL",
                label: i18n.get("userTransactions.list.filters.all"),
            },
            {
                value: "preferences.changepassword.send",
                label: i18n.get("activities.preferences.changepassword.send"),
            },
            {
                value: "session.login.legacy.passwordExpired",
                label: i18n.get("activities.session.login.legacy.passwordExpired"),
            },
            {
                value: "session.recoverPassword.step3",
                label: i18n.get("activities.session.recoverPassword.step3"),
            },
            {
                value: "session.recoverUser",
                label: i18n.get("activities.session.recoverUser"),
            },
            {
                value: "preferences.securityseals.modify",
                label: i18n.get("activities.preferences.securityseals.modify"),
            },
            {
                value: "session.login.success",
                label: i18n.get("activities.session.login.success"),
            },
            {
                value: "session.login.failed",
                label: i18n.get("activities.session.login.failed"),
            },
            {
                value: "request.changePersonalData.send",
                label: i18n.get("userTransactions.list.filters.ChangePersonalData"),
            },
            {
                value: "preferences.token.register",
                label: i18n.get("activities.preferences.token.register"),
            },
        ];
        return (
            <div className="filters-container">
                <Form className="form-content">
                    <Field
                        options={activities}
                        component={Selector}
                        name="activity"
                        renderAs="combo"
                        idForm={FORM_ID}
                    />
                    <Field
                        idField="dateFrom"
                        component={Date}
                        endDate={dateTo}
                        hidePlaceholder
                        idForm={FORM_ID}
                        isClearable
                        name="dateFrom"
                        selectsStart
                        showMonthYearDropdown
                        startDate={dateFrom}
                        minDate={moment().add(-maxDaysBack, "days")}
                        availableAllDayofWeek
                    />
                    <Field
                        idField="dateTo"
                        component={Date}
                        endDate={dateTo}
                        hidePlaceholder
                        idForm={FORM_ID}
                        isClearable
                        name="dateTo"
                        selectsEnd
                        showMonthYearDropdown
                        startDate={dateFrom}
                        minDate={dateFrom}
                        availableAllDayofWeek
                    />
                    <Button
                        block={!isDesktop}
                        label="userTransactions.list.filters.filter"
                        bsStyle="primary"
                        loading={fetching}
                        type="submit"
                    />
                    <Button
                        block={!isDesktop}
                        bsStyle="outline"
                        className="btn-clear-filters"
                        label="product.filters.clearFilters"
                        image="images/ui-icons/ui-clear-filters.svg"
                        loading={fetching}
                        onClick={this.handleClearFilters}
                    />
                </Form>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    filters: userTransactionsSelectors.getFilters(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: (props) => ({
            // activity: "ALL",
            activity: props.filters.activity ? props.filters.activity : "",
            dateFrom: props.filters.dateFrom ? props.filters.dateFrom : null,
            dateTo: props.filters.dateTo ? props.filters.dateTo : null,
        }),
        validationSchema: () =>
            Yup.lazy((values) =>
                Yup.object().shape({
                    dateFrom: values.dateTo
                        ? Yup.date()
                              .nullable()
                              .max(values.dateTo, i18n.get("userTransactions.list.filters.dateFrom.error"))
                        : Yup.date().nullable(),
                    dateTo: values.dateFrom
                        ? Yup.date()
                              .nullable()
                              .min(values.dateFrom, i18n.get("userTransactions.list.filters.dateTo.error"))
                        : Yup.date().nullable(),
                }),
            ),
        handleSubmit: ({ ...filters }, formikBag) => {
            const { dispatch } = formikBag.props;

            dispatch(userTransactionsActions.listRequest(filters, 1, true, formikBag));
        },
    }),
)(Filters);
