import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Field, withFormik } from "formik";
import withRouter from "react-router-dom/withRouter";
import { selectors as i18nSelectors } from "reducers/i18n";
import { selectors as formSelectors, actions as formActions } from "reducers/form";
import { selectors as templateSelectors } from "reducers/template";
import * as i18nUtils from "util/i18n";
import { compose } from "redux";
import * as FormFieldsComponents from "pages/forms/_components/_fields/Index";
import { bool, shape, func, string } from "prop-types";
import DisclaimerContainer from "components/DisclaimerContainer/DisclaimerContainer";
import I18n from "pages/_components/I18n";
import FormTransition from "../_components/FormTransition";
import * as configUtils from "../../../util/config";

const ID_FORM = "investments.shareholderAccountOpening";
const ID_ACTIVITY = `${ID_FORM}.send`;
const ID_ACTIVITY_PRE = `${ID_FORM}.pre`;

const ShareholderAccountOpeningForm = (props) => {
    const { mode, dispatch, location, fromBackoffice, isMobile } = props;
    useEffect(() => {
        if (mode === "edit" && !fromBackoffice) {
            const formData = {};
            dispatch(formActions.preForm({ idActivity: ID_ACTIVITY_PRE, formData }));
        }
    }, [dispatch, location, fromBackoffice, mode]);
    const { currentLang, transaction, preData, data } = props;
    const listProductDollar = preData?.listAccountsDollars || data?.listProductDollar || [];
    const listProductPesos = preData?.listAccountsPesos || data?.listProductPesos || [];

    const renderFields = (setFieldValue) => {
        const idTransaction = transaction?.idTransaction;
        const listProductDollarOptions = {
            options: listProductDollar.map((acc) => ({
                ...acc,
                balance: { currency: acc.currency, quantity: acc.balance },
                id: acc.idProduct,
            })),
        };
        const listProductPesosOptions = {
            options: listProductPesos.map((acc) => ({
                ...acc,
                balance: { currency: acc.currency, quantity: acc.balance },
                id: acc.idProduct,
            })),
        };
        const genericProps = {
            mode,
            lang: currentLang,
            idTransactionTicket: idTransaction,
            fromBackoffice,
            isRequired: true,
            idActivity: ID_ACTIVITY,
        };
        const showTermsData = mode === "edit" && !fromBackoffice;

        const handleChangeAccount = (option, currency) => {
            let accountSelected;
            if (currency === "pesos") {
                accountSelected = listProductPesos?.find((acc) => acc.idProduct === option);
            } else {
                accountSelected = listProductDollar?.find((acc) => acc.idProduct === option);
            }
            setFieldValue(`labelDebit${currency}`, accountSelected?.label);
        };

        return (
            <>
                <Field
                    {...genericProps}
                    component={FormFieldsComponents.ProductselectorCustom}
                    data={listProductPesosOptions}
                    key="listProductPesos"
                    name="accountPesos"
                    idField="listProductPesos"
                    handleChange={(e) => handleChangeAccount(e, "pesos")}
                    labelBackoffice={data.labelDebitpesos}
                    value={data.accountPesos}
                />
                <Field
                    {...genericProps}
                    component={FormFieldsComponents.ProductselectorCustom}
                    data={listProductDollarOptions}
                    key="listProductDollar"
                    name="accountDollar"
                    idField="listProductDollar"
                    handleChange={(e) => handleChangeAccount(e, "dollar")}
                    labelBackoffice={data.labelDebitdollar}
                    value={data.accountDollar}
                />
                <Field
                    {...genericProps}
                    component={FormFieldsComponents.Text}
                    key="email"
                    name="userEmail"
                    idField="email"
                    maxLength="50"
                    value={data.userEmail}
                />

                {data?.cancelReason && (
                    <div className="data-wrapper inline">
                        <span className="data-label">
                            <I18n id="forms.cancelRequest.cancelReason.ticket.label" />
                        </span>
                        <span className="data-text">{data.cancelReason}</span>
                    </div>
                )}

                {showTermsData && (
                    <>
                        <DisclaimerContainer
                            maxHeight={!isMobile ? "140px" : "200px"}
                            disclaimer="forms.investments.shareholderAccountOpening.disclaimer.termsAndConditions"
                            textSmall
                            className="ui-mb-7 ui-mt-3"
                        />
                        <Field
                            {...genericProps}
                            component={FormFieldsComponents.Selector}
                            optionList={[
                                {
                                    id: "1",
                                    label: i18nUtils.get(`forms.${ID_FORM}.termsAndConditions.checkbox.label`),
                                    link: configUtils.get(
                                        "mutualFunds.shareholderAccountOpening.termsAndConditions.url",
                                    ),
                                    nameLink: i18nUtils.get(
                                        `forms.${ID_FORM}.termsAndConditions.checkbox.label.nameLink`,
                                    ),
                                },
                            ]}
                            key="termsAndConditionsAcceptance"
                            // se cambia para que aparezca el titulo de campo solo si viene de backoffice
                            name={!fromBackoffice ? "termsAndConditionsAcceptance" : "termsAndConditions"}
                            idField={!fromBackoffice ? "termsAndConditionsAcceptance" : "termsAndConditions"}
                            renderAs="check"
                            value={data.termsAndConditionsAcceptance || false}
                            additionalClassName="ui-mb-3"
                        />
                        <Field
                            {...genericProps}
                            component={FormFieldsComponents.Selector}
                            optionList={[
                                {
                                    id: "1",
                                    label: i18nUtils.get(`forms.${ID_FORM}.investorProtectionCode.checkbox.label`),
                                    link: configUtils.get(
                                        "mutualFunds.shareholderAccountOpening.investorProtectionCode.url",
                                    ),
                                    nameLink: i18nUtils.get(
                                        `forms.${ID_FORM}.investorProtectionCode.checkbox.label.nameLink`,
                                    ),
                                },
                            ]}
                            key="investorProtectionCodeAcceptance"
                            // se cambia para que aparezca el titulo de campo solo si viene de backoffice
                            name={!fromBackoffice ? "investorProtectionCodeAcceptance" : "investorProtectionCode"}
                            idField={!fromBackoffice ? "investorProtectionCodeAcceptance" : "investorProtectionCode"}
                            renderAs="check"
                            value={data.investorProtectionCodeAcceptance || false}
                        />
                        {fromBackoffice && data.termsAndConditionsLink && (
                            // aparece debajo del campo de checkbox solo en backoffice
                            <Field
                                {...genericProps}
                                component={FormFieldsComponents.Text}
                                key="termsAndConditions"
                                name="termsAndConditionsLinkBackoffice"
                                idField="termsAndConditionsLinkBackoffice"
                                maxLength={150}
                                editing={false}
                                value={i18nUtils.get(`forms.${ID_FORM}.termsAndConditions.link.selected`)}
                            />
                        )}
                    </>
                )}
            </>
        );
    };

    const handleClickDownload = () => {
        const { idTransaction } = props.transaction || {};
        dispatch(formActions.downloadTicketCustom(idTransaction, ID_ACTIVITY));
    };

    const {
        nonWorkingDays = [],
        enabledWeekDays = [false, true, true, true, true, true, true, true],
        firstWorkingDate = new Date(),
        maxDaysToSchedule = 30,
        ...restPreData
    } = props.preData || {};

    const formProps = {
        title: `forms.${ID_FORM}.formTitle`,
        metadata: {
            draftsEnabled: false,
            templatesEnabled: false,
            schedulable: false,
            programable: false,
            nonWorkingDays,
            enabledWeekDays,
            firstWorkingDate,
            maxDaysToSchedule,
            idActivity: ID_ACTIVITY,
        },
        data: {
            listProductPesos,
            listProductDollar,
        },
        renderFields,
        useDefaultSubmit: true,
        preData: restPreData,
        isCustom: true,
        idActivity: ID_ACTIVITY,
        titleConfirmation: true,
        buttonLabel: `forms.${ID_FORM}.send`,
        handleClickDownload,
        exportList: "pdf",
    };
    return <FormTransition {...props} {...formProps} />;
};

ShareholderAccountOpeningForm.propTypes = {
    mode: string.isRequired,
    dispatch: func.isRequired,
    location: shape({}).isRequired,
    fromBackoffice: bool,
    currentLang: string.isRequired,
    transaction: shape({}).isRequired,
    data: shape({}).isRequired,
    preData: shape({}),
    useDefaultSubmit: bool,
    isMobile: bool,
};

ShareholderAccountOpeningForm.defaultProps = {
    fromBackoffice: false,
    preData: null,
    useDefaultSubmit: true,
    isMobile: false,
};

const mapStateToProps = (state) => ({
    id: formSelectors.getId(state),
    fetching: formSelectors.getFetching(state),
    currentLang: i18nSelectors.getLang(state),
    data: formSelectors.getData(state),
    transaction: formSelectors.getTransaction(state),
    childrenTransactions: formSelectors.getChildrenTransactions(state),
    parentTransaction: formSelectors.getParentTransaction(state),
    ticketConfirmation: true,
    templates: templateSelectors.getTemplateList(state),
    mode: formSelectors.getMode(state),
    credentialsGroups: formSelectors.getCredentialsGroups(state),
    isCancellingTransaction: formSelectors.getIsCancellingTransaction(state),
    preData: formSelectors.getPreData(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        enableReinitialize: true,
        validateOnChange: false,
        mapPropsToValues: () => ({
            accountBranch: "1",
            accountType: "",
            termsAndConditionsAcceptance: false,
        }),
    }),
    withRouter,
)(ShareholderAccountOpeningForm);
