import moment from "moment";
import * as config from "util/config";

/*
Este archivo sirve de librería para obtener y poder modificar de una sola vez la 'Fecha desde' por defecto
para los filtros de los listados.
Sirve para aquellos que no estén definidos en configuration.
*/

export const moveToBussinessDay = (date) => {
    // Evalúa si el día es sábado o domingo, y lo pasa automáticamente al lunes siguiente
    const dayOfWeek = date.day();
    let newDay;

    if (dayOfWeek === 0) {
        newDay = date.add(1, "d");
        return newDay;
    }

    if (dayOfWeek === 6) {
        newDay = date.add(2, "d");
        return newDay;
    }

    return date;
};

const defaultDateFromList = () => ({
    checks: {
        dateFrom: moveToBussinessDay(moment().add(-6, "months")),
        dateTo: moment(),
    },
    accountsQR: moveToBussinessDay(moment()),
    accountsMovements: {
        dateFrom: moveToBussinessDay(moment().add(-config.get("accounts.statements.maxMonthsBack", 6), "months")),
        dateTo: moment(),
    },
    checksDeferred: {
        dateFrom: moveToBussinessDay(moment().add(-config.get("checks.deferred.maxDaysBack", 365), "days")),
        dateTo: moveToBussinessDay(moment().add(config.get("checks.deferred.maxDaysForward", 365), "days")),
    },
    transactions: moveToBussinessDay(moment().subtract(6, "months")),
    comex: {
        dateFrom: moveToBussinessDay(moment().add(-24, "months")),
        dateTo: moment(),
    },
    loans: {
        dateFrom: moveToBussinessDay(moment().add(-config.get("loans.numberOfMonthView", 12), "months")),
        dateTo: moment(),
    },
    mutualFundsMovements: {
        dateFrom: moveToBussinessDay(moment().add(-config.get("mutualFunds.movements.defaultDaysBack", 7), "days")),
        dateTo: moment(),
    },
    echeqs: {
        paymentDateFrom: moveToBussinessDay(moment().add(-7, "days")),
        paymentDateTo: moveToBussinessDay(moment().add(365, "days")),
        maxDaysToEmmit: moment().add(config.get("echeqs.max_days_to_emmit", 360), "days"),
    },
    paymentsList: {
        dateFrom: moveToBussinessDay(moment().add(-config.get("payment.supplier.default_days.search", 15), "days")),
        dateTo: moveToBussinessDay(moment()),
    },
    paymentsListSent: {
        processed: {
            dateFrom: moveToBussinessDay(moment().add(-config.get("payment.supplier.default_days.search", 15), "days")),
        },
        rejected: {
            dateFrom: moveToBussinessDay(moment().add(-config.get("default_days.Search_process.Transfer", 60), "days")),
        },
    },
    userTransactions: {
        dateFrom: moveToBussinessDay(moment().add(-config.get("userTransactions.maxDaysBack", 365), "days")),
        dateTo: moment(),
    },
});

export default defaultDateFromList;
